import React, { useState, useContext } from 'react'
import './Iphone.css'
import backgroundImage from '../../../Videos/iphoneBackground.png'
import blendMode from '../../../Videos/qrontop.png'
import blendBack from '../../../Videos/qrBack.jpg'
import aspectIphone from '../../../Videos/work_iphone.png'
import { PopupModal } from '../../../Contexts/PopupModal'

function Iphone() {

    const {modalStatus, setModalStatus} = useContext(PopupModal)

    const modalOpen = () => {
      if(!modalStatus) {
        setModalStatus(true);
      }
      else {
        setModalStatus(false)
      }
    }

    return (
      <>
      <div className='work__iphone__container'>
        <span className='work__iphone__span'>
            <button onClick={modalOpen} className='work__open__modal'>Watch Video</button>
            <img src={aspectIphone} alt="" width="267" height="579" />
        </span>
      </div>
      </>
    );
  }
  
  export default Iphone;