import React, { useState, useContext } from 'react'
import './FooterWork.css'
import smallLogo from '../../../Videos/logoSmall.png'
import blackLogo from '../../../Videos/black-transparent-logo.png';
import { Link, useNavigate } from 'react-router-dom';

function FooterWork() {

    return (
      <footer className='footer__V2'>
        <div className='footer__v2__padding__'>
            <div className='footer__v2__grid'>
                <div className='footer__v2__start__container'>
                    <div className='footer__v2__start__gridrow'>
                        <img className='footer__small__logo' src={smallLogo} />
                        <h2 className='footer__main__headline'>A few minutes in return for a revolution</h2>
                        <div class="footer__main__subtext">Sign up now and start taking advantage of our incredible benefits.</div>
                        <button className='footer__main__action__button'>Get Started</button>
                    </div>
                </div>
                <div className='footer__v2__logo__container'>
                    <div className='footer__group__1'>
                        <Link to='/about'><h3 className='footer__v2__hyperlinks'>About Us</h3></Link>
                        <Link to='/privacy'><h3 className='footer__v2__hyperlinks'>Privacy Policy</h3></Link>
                        <Link to='/terms'><h3 className='footer__v2__hyperlinks'>Terms and Conditions</h3></Link>
                    </div>
                    <div className='footer__group__2'>
                        <div className='footer__v2__big__logo'>
                            {/* <img className='__txt__logo' src={blackLogo} /> */}
                            <h1 className='footer__font__logo'>VUEKART</h1>
                            <img className='__svg__logo' src={smallLogo} />
                        </div>
                        <div className='footer__legal__text'>
                            Ⓒ 2023 Untitled Finance Inc. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </footer>
    );
  }
  
  export default FooterWork;