

import React, { useEffect, useState, useRef, useContext  } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ShortPlayer.css'
import videoface from '../Videos/face.mp4'
import qrsal from '../Videos/qrsal.mov'
import testvideo from '../Videos/testvideo.mp4'
import product from '../Videos/product.mp4'
import { PopupModal } from '../Contexts/PopupModal';

function ShortPlayer({fireURL}) {

    const videoRef = useRef(null);  
    const [pause, setPaused] = useState(false)
    const [show, setShow] = useState(false)
    const [playState, setPlayState] = useState('playing')
    const [seekbarWidth, setSeekbarWidth] = useState('0%');
    const [scrubbleMode, setScrubbleMode] = useState(false)
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [toIncrement, setToIncrement] = useState(0)

    const navigate = useNavigate()

    const {modalStatus, setModalStatus} = useContext(PopupModal)

    const playbackControl = (e) => {

        const video = videoRef.current;
        const checkTime = videoRef.current;

        if(!show) {
            setShow(true)
        }

        setTimeout(() => {
            setShow((prevShow) => {
              if (prevShow) {
                return false;
              }
              return prevShow;
            });
          }, 400);

        if (video.paused) {
        // Video is paused
        // console.log('Video is paused');
        if (playState == 'paused') {
            setPlayState('playing');
        }
        video.play();
        } else {
        // Video is playing
        // console.log('Video is playing');
        if(playState == 'playing') {
            setPlayState('paused');
        }

        video.pause();

        }
    }

    const closePlayer = (e) => {
        // e.stopPropagation();
        console.log('Modal S' + modalStatus)

        if (modalStatus) {
            setModalStatus(false)
        }
        else {
            // navigate('/')
        }
    }

    useEffect(() => {
        const video = videoRef.current;
        // Listen for the 'timeupdate' event to update the seekbar
        video.addEventListener('timeupdate', () => {
          const currentTime = video.currentTime;
          const duration = video.duration;
          const newWidth = (currentTime / duration) * 100;
          setSeekbarWidth(newWidth)
        });
    
    
        return () => {
          // Clean up the event listener when the component unmounts
          video.removeEventListener('timeupdate', () => {});
        };
      }, []);

    useEffect(() => {
        const video = videoRef.current;

        const handleVideoPause = () => {
            // This function will be called whenever the video is paused
            console.log('Video is paused');
        //   if(!scrubbleMode) {
        //     setScrubbleMode(true)
        //   }
            setScrubbleMode((prevShow) => {
            if (!prevShow) {
                return true;
            }
            return prevShow;
            });
        };

        const handleVideoPlay = () => {
            // This function will be called whenever the video is paused
            console.log('Video is Playings');
            // if(scrubbleMode) {
            //   setScrubbleMode(false)
            // }
            setScrubbleMode((prevShow) => {
                if (prevShow) {
                    return false;
                }
                return prevShow;
                });
            };

        video.addEventListener('pause', handleVideoPause);
        video.addEventListener('play', handleVideoPlay);

        return () => {
            // Clean up the event listener when the component unmounts
            video.removeEventListener('pause', handleVideoPause);
            video.removeEventListener('play', handleVideoPlay);
        };
    }, []);

    const minSwipeDistance = 50 

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const onTouchStart = (e) => {
        
        e.stopPropagation();
    
        console.log('wow')
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }
    
    const onTouchMove = (e) => {
        const video = videoRef.current;

        if (!touchStart) return
        
        const distance = e.targetTouches[0].clientX - touchStart

        const currentTime = video.currentTime;
          const duration = video.duration;
          const newWidth = (currentTime / duration) * 100;

        console.log(`current time ${currentTime}`)

        const percentageMoved = (distance / (width - 16)) * 100
        console.log(distance)
        console.log(percentageMoved)
        const toMove = newWidth + percentageMoved
        setSeekbarWidth(toMove)
        
        setToIncrement(percentageMoved)
        setTouchEnd(toMove)
        console.log(`toMove ${toMove}`)
    }
    
    const onTouchEnd = () => {
        const video = videoRef.current;

        if (!touchStart || !touchEnd) return
        const distance = touchEnd - touchStart

        const percentageMoved = (distance / (width - 16)) * 100
        console.log(distance)
        console.log(percentageMoved)

        console.log(`tourch end ${touchEnd}`)
        setSeekbarWidth(touchEnd)
        const percentageForVideo = (video.duration * toIncrement) / 100
        video.currentTime = (seekbarWidth * video.duration) / 100

        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
        // add your conditional logic here
    }

    // const timeUpdating = (e) => {
    //     console.log(e.currentTime)
    // }
  
    
  return (
    <>
    {/* <div className='hosted-short-main-mobile'>
        <div className='hosted-mobile-flex'>
            <div className='hosted-mobile-height'>
                <div className='hosted-mobile-height-inner'>
                    <div className='hosted-mobile-grow'>
                        
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    <section  className='hosted-mobile-section'>
        <main className='hosted-mobile-main'>
            <div className='hosted-mobile-main-inner'>
                <div className='hosted-mobile-main-full'>
                    <div onClick={playbackControl} className='__transform'>
                        <div  className='__mobile_relative'>
                            <div className='__mobile_video-container'>
                            <video controlsList="nodownload nofullscreen noremoteplayback" ref={videoRef} className="mobile-hosted-video" autoPlay muted loop controls='false' src={fireURL}></video>
                            </div>
                        </div>
                        <div className='controllers-buttons'>
                            <div className={'account-hosted' + (scrubbleMode ? ' scrubble-margin' : '')}>
                                <div className='like-holder'>
                                <svg aria-label="Like" className="hosted-like-svg" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Like</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
                                <span className='hosted-like-span'>
                                    <span className='hosted-like-text'>234.5K</span>
                                </span>
                                </div>

                                <div className='like-holder'>
                                {/* <svg aria-label="Like" className="hosted-like-svg" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Like</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg> */}
                                <svg className="hosted-like-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 12C2 11.0714 2 10.6071 2.03425 10.2156C2.41491 5.86458 5.86458 2.41491 10.2156 2.03425C10.6071 2 11.0714 2 12 2V2C12.9286 2 13.3929 2 13.7844 2.03425C18.1354 2.41491 21.5851 5.86458 21.9658 10.2156C22 10.6071 22 11.0714 22 12V18.3636C22 19.8983 22 20.6657 21.6011 21.1986C21.4868 21.3512 21.3512 21.4868 21.1986 21.6011C20.6657 22 19.8983 22 18.3636 22H12C11.0714 22 10.6071 22 10.2156 21.9658C5.86458 21.5851 2.41491 18.1354 2.03425 13.7844C2 13.3929 2 12.9286 2 12V12Z" stroke="white" stroke-width="2"/>
<path d="M8.25 10.75L15.75 10.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 15.75H15.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                <span className='hosted-like-span'>
                                    <span className='hosted-like-text'>234.5K</span>
                                </span>
                                </div>

                                <div className='like-holder'>
                                {/* <svg aria-label="Like" className="hosted-like-svg" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Like</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg> */}
                                <svg className="hosted-like-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.1875 12.3978L5.5304 10.8457C3.1768 10.0613 2 9.66908 2 8.94828C2 8.22748 3.1768 7.83528 5.5304 7.05088L5.53041 7.05088L18.866 2.60644C20.5218 2.0546 21.3497 1.77867 21.7867 2.21571C22.2237 2.65275 21.9477 3.48063 21.3957 5.13639L16.9503 18.4704C16.1659 20.8232 15.7737 21.9996 15.0529 21.9996C14.3322 21.9996 13.94 20.8232 13.1556 18.4704L11.6024 13.8114L17.0256 8.38909C17.4161 7.9986 17.4162 7.36544 17.0257 6.97488C16.6352 6.58432 16.002 6.58427 15.6115 6.97476L10.1875 12.3978Z" fill="white"/>
</svg>

                                <span className='hosted-like-span'>
                                    <span className='hosted-like-text'>234.5K</span>
                                </span>
                                </div>
                                <div className='account-icon-hosted'>
                                    <div className='account-icon-position'>
                                    <img alt="Audio image" crossorigin="anonymous" src="https://images.crunchbase.com/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/v1403242711/t27lgufvhpzm3myfhsic.jpg" className="__hosted__img" style={{objectFit: 'cover'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'seek-bar' + (scrubbleMode ? ' scrubble-mode-active' : '')}>
                            <div className='playerControlBarHost' role='progressbar' aria-valuenow='80'>
                                <div className="PlayerControlsProgressBarHostProgressBarLine">
                                    <div className="PlayerControlsProgressBarHostProgressBarBackground"></div>  
                                    <div className="PlayerControlsProgressBarHostProgressBarLoaded" style={{width: '0%'}}></div>
                                    <div className="PlayerControlsProgressBarHostProgressBarHovered" style={{width: '0%'}}></div>
                                    <div className="PlayerControlsProgressBarHostProgressBarPlayed" style={{width: seekbarWidth + '%'}}></div>
                                </div>
                                <div className="PlayerControlsProgressBarHostProgressBarPlayheadWrapper" style={{marginLeft: seekbarWidth + '%'}}>
                                    <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} class={"PlayerControlsProgressBarHostProgressBarPlayheadDot" + (scrubbleMode ? ' PlayerControlsProgressBarHostProgressBarPlayheadDotIsScrubbableMode' : '')}></div>
                                </div>
                            </div>
                        </div>
                        
                        <button onClick={closePlayer} className='close-button-hosted'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5L19 19ZM19 5L5 19Z" fill="white"/><path d="M5 5L19 19M19 5L5 19" stroke="white" stroke-width="2" stroke-linecap="round"/></svg>
                        </button>
                        
                        <div className={!show ? 'playback-control-centered' : 'playback-controled-active'}>
                            <div className='__playback-back'>
                                <span className='__playback-span'>
                                    {playState == 'paused' ? <svg viewBox="0 0 24 24" fill="none"><path d="M6 6.134v11.732c0 .895 1.03 1.438 1.822.951l9.628-5.866c.733-.441.733-1.46 0-1.914L7.822 5.183C7.029 4.696 6 5.239 6 6.134z" fill="currentColor"></path></svg> : <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.188 5C6.979 5 6 5.98 6 7.188v9.625a2.188 2.188 0 004.375 0V7.188C10.375 5.979 9.395 5 8.187 5zm7.874 0c-1.208 0-2.187.98-2.187 2.188v9.625a2.188 2.188 0 004.375 0V7.188C18.25 5.979 17.27 5 16.062 5z" fill="currentColor"></path></svg>}
                                    
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </main>
    </section>
    <div className='hosted-short-main-desktop'></div>
    </>
    
    
  );
}

export default ShortPlayer;
