
import './CreatorMotive.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function CreatorMotive() {

  return (
    <form className='step-wrapper'>
        <div className='step-hero-wrapper'>
            <h1 className='step-main-title-progress'>What motivates you to create product videos</h1>
        </div>
        <div className='question-motive-group'>
            <div className='motive-box'>
                <div className='motive-group-container'>
                    <div className='motive-text-flex'>
                        <div className='text-field-main-motive'>
                        <textarea 
                        aria-describedby="text-area-count-hint" 
                        aria-required="true" 
                        placeholder="I want to do my best work:" 
                        maxlength="350" 
                        className="main-text-field-motive" 
                        spellcheck="false"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
  );
}

export default CreatorMotive;
