
import './CreatorAbout.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import QuestionHeader from '../question/QuestionHeader';
import CreatorName from './pages/CreatorName';
import CreatorCategory from './pages/CreatorCategory';
import CreatorTime from './pages/CreatorTime';
import CreatorSocial from './pages/CreatorSocial';
import CreatorMotive from './pages/CreatorMotive';

function CreatorAbout() {
  const navigate = useNavigate();  

  const [questionIndex, setQuestionIndex] = useState(1);

  const increamentIndex = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setQuestionIndex(questionIndex + 1);
  };

  const previousIndex = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if(questionIndex <= 1) {
        navigate('/creator')
    }
    else {
        setQuestionIndex(questionIndex - 1);
    }
    
  };

  useEffect(() => {
    setTimeout(() => {
        const creator = document.querySelector('.page-container-margin');
        const progress_container = document.querySelector('.page-progress-container');
        if(creator) {
            
            creator.style.opacity = 1;
            creator.style.transform = 'translateY(0px)';
            progress_container.style.opacity = 1;
        }

    }, 100);
    }, []); 
    
  return (
    <div>
        <main className='questionaire-main'>
            <QuestionHeader />
            
            <div className='question-section-page'>
                <div className='page-container-question'>
                    <div className='page-container-margin'>
                        <div className='page-container-display'>
                            <div className='page-container-padding'>
                                <div className='page-main-start-question'>
                                    {questionIndex == 1 && <CreatorName />}
                                    {questionIndex == 2 && <CreatorCategory />}
                                    {questionIndex == 3 && <CreatorTime />}
                                    {questionIndex == 4 && <CreatorSocial />}
                                    {questionIndex == 5 && <CreatorMotive />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='page-progress-container'>
                    <div className='continue-progress'>
                        <div className='steps-progress-container'>
                            <div className='steps-progress-main-bar'>
                                <div style={{ transform: `translateX(${10 * questionIndex}%)`, transition: 'transform 600ms linear 0s' }} class="step-bar-transform"></div>
                            </div>
                        </div>
                        <div className='continue-progress-inner'>
                            <div className='back-button-container'>
                                <button onClick={previousIndex} className='back-button-inner'>
                                    <svg fill="currentColor" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg" class="back-arrow"><path d="M12.75 18H10l-7-7 7-7h2.75l-6 6H19v2H6.75l6 6z"></path></svg>
                                </button>
                            </div>
                            <div className='continue-next-button-container'>
                                <button onClick={increamentIndex} className='continue-next-button'>
                                    <p class="progress-next-button-text">Next</p>
                                    <svg className='arrow-creator' width="15" height="13" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.25 14H9L16 7L9 0H6.25L12.25 6H0V8H12.25L6.25 14Z" fill="black"/></svg>

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
  );
}

export default CreatorAbout;
