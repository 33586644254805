

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Thankyou.css'
import QuestionHeader from './question/QuestionHeader';
import Loading from './Loading';

function Thankyou() {

    useEffect(() => {
        setTimeout(() => {
            const creator = document.querySelector('.page-container-margin');
            if(creator) {    
                creator.style.opacity = 1;
                creator.style.transform = 'translateY(0px)';    
            }
        }, 100);
        }, []); 

    
  return (
    <div>
        <main className='questionaire-main transition-pricing'>
            <QuestionHeader />
            
            <div className='question-section-page'>
                <div className='page-container-question'>
                    <div className='page-container-margin'>
                        <div style={{paddingBottom: '50px'}}className='page-container-display'>
                            <div className='page-container-padding'>
                                <div className='page-pricing-gird page-pricing-main'>
                                  <div className='pricing-header-container'>
                                      <h2 className='thank-you-title'>Thank you</h2>
                                      <div className='thank-you-description'>Your order is being Processed and a Producer will call you between 11am and 1pm on the next working day. If it is inconvenient for you to speak, don't worry, we will call back!</div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </main>    
    </div>
  );
}

export default Thankyou;
