

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Privacy.css'
import LinkHeader from './question/LinkHeader';

function Privacy() {
    
  return (
    <>
        <LinkHeader />
        <div style={{maxHeight: '100%'}} className='about-page-container'>
            <div className='about-us-title-container'>
                <div className='about-us-title-inner about-us-tldr'>
                    <section className='about-title-section'>
                        <h1 className="about-title-main about-title-wrap">Privacy Policy</h1>
                    </section>
                </div>
            </div>
            <div className='about-info-container info-padding'>
                <div className='info-container-inner'>
                    <article>
                        <p>
                        At VueKart accessible from https://VueKart.com, one of our main priorities is the privacy of our Customers. This Privacy Policy document describes what information is collected and recorded by VueKart and how we use it.
This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect with and from VueKart. This policy is not applicable to any information collected offline or via channels other than this website.
                        </p>
                        <br></br>
                        <p>Consent</p>
                        <p>
                        Consent
By using our website, you hereby consent to our Privacy Policy and agree to its terms.
Collecting and Using Your Personal Data.

                        </p>
                        <br></br>
                        <p>Types of Data Collected</p>
                        <p>Personal Data
While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                        <p>1. Name</p>
                        <p>2. Email address –Service updates, Notifications and Reminders (Password,etc)</p>
                        <p>3. Postal address including Location such as ZIP code or city name</p>
                        <p>4. Job Title</p>
                        <p>5. Usage Data</p>
                        <p>6. Mobile Phone number or other Telephone number</p>
                        <p>7. Bank details where applicable</p>
                        <p>We will never sell or give your email address to anyone.
</p>
                        <br></br>
                        <p>Usage Data</p>
                        <p>Usage Data is collected automatically when using the Service.
Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
</p>
                        <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
</p>
                        <br></br>
                        <p>Tracking Technologies and Cookies</p>
                        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                        <p>Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</p>
                        <p>Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</p>
                        <p>Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
In particular, the VueKart Cookie stores information like your ZIP code and time zone, and also preferences such as Content you watch.
</p>
                        <br></br>
                        <p>Use of Your Personal Data</p>
                        <p>VueKart may use Personal Data for the following purposes:
</p>
                        <p>To provide and maintain our Service, including to monitor the usage of our Service.</p>
                        <p>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                        <p>For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                        <p>To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
                        <p>To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
</p>
                        <p>To manage Your requests: To attend and manage Your requests to Us.</p>
                        <p>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
                        <p>For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
                        <br></br>
                        <p>We may share Your personal information in the following situations:
</p>
                        <p>With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</p>
                        <p>For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</p>                   
                        <p>With Your consent: We may disclose Your personal information for any other purpose with Your consent.</p>
                        <br></br>
                        <p>Retention of Your Personal Data
</p>
                        <p>VueKart will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
VueKart will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
</p>
                        <br></br>
                        <p>Transfer of Your Personal Data
</p>
                        <p>Your information, including Personal Data, is processed at VueKart’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of Your jurisdiction.
Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
VueKart will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
</p>
                        <br>
</br>
                        <p>Disclosure of Your Personal Data</p>
                        <p>Business Transactions
If VueKart is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
</p>
<br>
</br>
                        <p>Law enforcement</p>
                        <p>Under certain circumstances,VueKart may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
</p>
                        <br></br>
                        <p>Other legal requirements
</p>
                        <p>VueKart may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                        <p>1. Comply with a legal obligation</p>
                        <p>2. Protect and defend the rights or property of VueKart</p>   
                        <p>3. Prevent or investigate possible wrongdoing in connection with the Service</p> 
                        <p>4. Protect the personal safety of Users of the Service or the public</p>
                        <p>5. Protect against legal liability</p>
                        <br></br>
                        <p>Security of Your Personal Data
</p>
                        <p>
                        The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.

                        </p>
                        <br></br>
                        <p>CCPA Privacy Rights (Do Not Sell My Personal Information)</p>
                        <p>Under the CCPA, among other rights, California consumers have the right to:
Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
Request that a business delete any personal data about the consumer that a business has collected.
Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
</p>
                        <br></br>
                        <p>GDPR Data Protection Rights
</p>
                        <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
</p>
                        <br></br>
                        <p>Children’s Privacy
</p>
                        <p>Our Service does not address anyone under the age of 15. We do not knowingly collect personally identifiable information from anyone under the age of 15. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 16 without verification of parental consent, We take steps to remove that information from Our servers.
If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent’s consent before We collect and use that information.
</p>
                        <br></br>
                        <p>Links to Other Websites
</p>
                        <p>Our Service may contain links to other websites that are not operated by us. If You click on a third party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit.
We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
</p>
                        <br></br>
                        <p>Changes to this Privacy Policy
</p>
                        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the “Last updated” date at the top of this Privacy Policy.
You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
</p>
                        <br></br>
                        <p>Contact Us</p>
                        <p>If you have any questions about this Privacy Policy, You can contact us:
</p>
                        <p>By email: founders@VueKart.com, India Phone: +917819061934</p>
                        <p>UK phone: +44(0)7517514288</p>
                        <p>UK Address 4 Brentfield Gardens NW2 1JP</p>
                        <br></br>
                        <p>How to complain
</p>
                        <p>If you have any concerns about our use of your personal information, you can make a complaint to us by using any of the contact details above.
You can also complain to the ICO if you are unhappy with how we have used your data.
The ICO’s address:           
Information Commissioner’s Office
Wycliffe House
Water Lane
Wilmslow
Cheshire
SK9 5AF
</p>
                        <p>Helpline number: 0303 123 1113
ICO website: https://www.ico.org.uk</p>
                    </article>
                </div>
            </div>
        </div>
        </>
  );
}

export default Privacy;
