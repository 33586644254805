
import './CreatorName.css';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import NextSeller from '../../seller/pages/NextSeller';
import { LoginContext } from '../../../Contexts/LoginContext';
import { ButtonOpacity } from '../../../Contexts/ButtonOpacity';

function CreatorName({ onPreviousClicked, onNextClicked}) {

  const {
    finalValues, 
    setFinalValues,
    firstName, 
    setFirstName, 
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    domain,
    setDomain
  } = useContext(LoginContext)

  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');
  // const [email, setEmail] = useState('');
  // const [domain, setDomain] = useState('');
  const [isInputFocusedFirst, setInputFocusedFirst] = useState(false);
  const [isAllInfo, setIsAllInfo] = useState(false)

  // const sendThis = (event) => {
  //   props.getName('My name is Dean Winchester & this is my brother Sammie');
  // };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);   
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);   
  };
  const handleEmailAddress = (e) => {
    setEmail(e.target.value);   
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);   
  };
  const handleDomainChange = (e) => {
    setDomain(e.target.value);   
  };

  const handleInputFocusFirst = () => {
    setInputFocusedFirst(true);
  };

  const sendValuesToParent = () => {
    const addValues = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      domain: domain

  };

  // const updateParentValues = [...finalValues, addValues];

  setFinalValues(addValues);
  }

  const handleFocus = (event) => {
    event.preventDefault()
    const dataValue = event.currentTarget.getAttribute('data-position');
    const numericValue = parseFloat(dataValue); // Parse the string to a float
    const underline = document.querySelectorAll('.underline-label-bar')

    underline[numericValue].classList.add('field-underline-active')
  };

  const handleBlur = (event) => {
    event.preventDefault()
    const dataValue = event.currentTarget.getAttribute('data-position');
    const numericValue = parseFloat(dataValue); // Parse the string to a float
    const underline = document.querySelectorAll('.underline-label-bar')

    if(underline[numericValue].classList.contains('field-underline-active')) {
        underline[numericValue].classList.remove('field-underline-active')
    }
    
  };
  
  useEffect(() => {
    setTimeout(() => {
        const creator = document.querySelector('.step-wrapper');
        if(creator) {
            
            creator.style.opacity = 1;
            creator.style.transform = 'translateY(0px)';
            
        }

    }, 100);
   }, []); 

   if ((firstName !== null && firstName !== '') && (lastName !== null && lastName !== '') && (email !== null && email !== '') && (phoneNumber !== null && phoneNumber !== '' && phoneNumber.toString().length > 5)) {
    //if (firstName !== null && firstName !== '') {

    if (!isAllInfo) {
      setIsAllInfo(true);
    }
   }
   else {
    if (isAllInfo) {
      setIsAllInfo(false);
    }
   }
    
  return (
    <form className='step-wrapper'>
        <div className='step-hero-wrapper'>
            <h1 className='step-main-title-progress'>Let's start with the basics</h1>
        </div>
        <div className='step-first-name'>
            <div className='basic-info-wrapper'>
                <label className='first-name-wrapper'>
                    <div className='first-name-container'>
                        <div className='first-name-label-container'>
                            <p class="first-name-title">First Name</p>
                        </div>
                        <div className='first-name-input-container'>
                        <input spellcheck="false" onBlur={handleBlur} onFocus={handleFocus} data-position="0" aria-required="true" placeholder="Stephen" maxlength="124" class="first-name-input" value={firstName} onChange={handleFirstNameChange} />
                        </div>
                    </div>
                </label>
                <div class="underline-label"><div class={"underline-label-bar"}></div></div>

                <label className='first-name-wrapper'>
                    <div className='first-name-container'>
                        <div className='first-name-label-container'>
                            <p class="first-name-title">Last Name</p>
                        </div>
                        <div className='first-name-input-container'>
                        <input spellcheck="false" onBlur={handleBlur} onFocus={handleFocus} data-position="1" aria-required="true" placeholder="Henrik" maxlength="124" class="first-name-input" value={lastName} onChange={handleLastNameChange} />
                        </div>
                    </div>
                </label>
                <div class="underline-label"><div class="underline-label-bar"></div></div>

                <label className='first-name-wrapper'>
                    <div className='first-name-container'>
                        <div className='first-name-label-container'>
                            <p class="first-name-title">Email Address</p>
                        </div>
                        <div className='first-name-input-container'>
                        <input spellcheck="false" onBlur={handleBlur} onFocus={handleFocus} data-position="2" aria-required="true" placeholder="domain@company.com" maxlength="124" class="first-name-input" value={email} onChange={handleEmailAddress} />
                        </div>
                    </div>
                </label>
                <div class="underline-label"><div class="underline-label-bar"></div></div>

                <label className='first-name-wrapper'>
                    <div className='first-name-container'>
                        <div className='first-name-label-container'>
                            <p class="first-name-title">Phone Number</p>
                        </div>
                        <div className='first-name-input-container'>
                        <input onBlur={handleBlur} onFocus={handleFocus} data-position="3" type="number" aria-required="true" placeholder="(800) 555-0199" maxlength="124" class="first-name-input" value={phoneNumber} onChange={handlePhoneNumberChange}/>
                        </div>
                    </div>
                </label>
                <div class="underline-label"><div class={"underline-label-bar"}></div></div>

                <label className='first-name-wrapper'>
                    <div className='first-name-container'>
                        <div className='first-name-label-container'>
                            <p class="first-name-title">Online Store</p>
                        </div>
                        <div className='first-name-input-container'>
                        <input spellcheck="false" onBlur={handleBlur} onFocus={handleFocus} data-position="4" aria-required="false" placeholder="www.domain.com" maxlength="500" class="first-name-input" value={domain} onChange={handleDomainChange}/>
                        </div>
                    </div>
                </label>
                <div class="underline-label"><div class={"underline-label-bar"}></div></div>
                
            </div>
        </div>
        <ButtonOpacity.Provider value={{isAllInfo, setIsAllInfo}}>
          <NextSeller onNextClicked={() => {onNextClicked();}} onPreviousClicked={onPreviousClicked}/>
        </ButtonOpacity.Provider>
    </form>
  );
}

export default CreatorName;
