
import './CreatorSocial.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function CreatorSocial() {

  const [isMouseOvered, setMouseOvered] = useState(false);
  const [socials, setSocials] = useState([{
    accountNumber: 'Social Account 1', placeholder: 'twitter.com/steve', platform: "Twitter"
  }]);

  function MouseOver(event) {
    event.preventDefault();
      if (!isMouseOvered) {
        setMouseOvered(true)
      }
  }

  function MouseOut(event) {
    event.preventDefault();
    if (isMouseOvered) {
        setMouseOvered(false)
      }
  }

  const addSocialAccount = () => {
    if (socials.length == 1) {
            const newSocial = {
                accountNumber: 'Social Account 2',
                placeholder: 'youtube.com/@steve',
                platform: "Youtube"
            };
        
            const updatedSocials = [...socials, newSocial];
        
            setSocials(updatedSocials);
    }
    else if(socials.length == 2) {
        const newSocial = {
            accountNumber: 'Social Account 3',
            placeholder: 'instagram.com/steve',
            platform: "Instagram"
        };
    
        const updatedSocials = [...socials, newSocial];
    
        setSocials(updatedSocials);
    }
  };

  useEffect(() => {
    setTimeout(() => {
        const creator = document.querySelector('.step-wrapper');
        if(creator) {
            
            creator.style.opacity = 1;
            creator.style.transform = 'translateY(0px)';
            
        }

    }, 100);
   }, []); 
    
  return (
    <form className='step-wrapper'>
        <div className='step-hero-wrapper'>
            <h1 className='step-main-title-progress'>Social Media Profiles</h1>
        </div>
        <div className='question-category-group'>
            {socials.map((socialItem, socialIndex) => {
                return (
                    <>
                    <label data-testid="input-list-label" className="social-media-label">{socialItem.accountNumber}</label>
                    <label className='social-media-wrapper'>
                        <div className='first-name-container'>
                            <div className='first-name-label-container'>
                                <p className="first-name-title">{socialItem.platform}</p>
                            </div>
                            <div className='first-name-input-container'>
                            <input aria-required="true" placeholder={socialItem.placeholder} maxlength="124" className="first-name-input"/>
                            </div>
                        </div>
                    </label>
                    <div className="underline-label"><div className={"underline-label-bar"}></div></div>
                    </>
                );
            })}
            {socials.length !== 3 && 
                <button  type="button" onClick={addSocialAccount} className='add-more-button'>
                <span className='add-more-social'>
                    <span className='add-more-container'>
                        <p onMouseOver={MouseOver} onMouseOut={MouseOut} className="add-more-text">Add More</p>
                        <svg onMouseOver={MouseOver} onMouseOut={MouseOut} fill="currentColor" height="22" viewBox="0 0 22 22" width="16px" xmlns="http://www.w3.org/2000/svg" className="add-more-svg"><path d="M12 3h-2v7H3v2h7v7h2v-7h7v-2h-7V3z"></path></svg>
                    </span>
                    <span className="underline-text-main"><div className={"underline-bar-main" + (isMouseOvered ? " underline-active-scale" : "")}></div></span>
                </span>
                </button>
            }
        </div>
    </form>
  );
}

export default CreatorSocial;
