
import './SellerStarted.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function SellerStarted({ onNextClick }) {

    useEffect(() => {
        setTimeout(() => {
            const question_main = document.querySelector('.question-grid-container');
            if(question_main) {
                question_main.style.opacity = 1;
                question_main.style.transform = 'translateY(0px)';  
            }
        }, 100);
       }, []); 

  return (
    <div className='question-grid-container'>
        <div className='question-grid-content'>
            <h1 className='seller-get-started-title'>Let's start by knowing you</h1>
            <p className="seller-get-started-description">Answer a few quick questions, and we’ll create high-quality product video for you.</p>
            <button onClick={onNextClick} className='start-now-button'>
            <p font-size="3" color="#000000" class="start-now-text">Continue</p>
            <svg color="#000000" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg" className="arrow-start"><path d="M9.25 18H12l7-7-7-7H9.25l6 6H3v2h12.25l-6 6z"></path></svg>
            </button>
        </div>
    </div>
  );
}

export default SellerStarted;
