

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import QuestionHeader from './question/QuestionHeader';
import Loading from './Loading';
import videoface from '../Videos/face.mp4'
import qrvideo from '../Videos/qrsal.mov'
import SearchingLoading from './SearchingLoading';
import axios from 'axios';
import './VideoRoute.css'
import ShortPlayer from './ShortPlayer';

function VideoRoute() {
  const [fetched, setFetched] = useState(false)  
  const [video, setVideo] = useState(null)
  const [notfound, setNotFound] = useState(false)
  
  const { id } = useParams();


  useEffect(() => {
    axios.get(`https://the-webbing-402113.el.r.appspot.com/videos/${id}`).then((response) => {
        if (response.data === 'Video not found') {
            console.log('Not Found')
            if (!notfound) {
                setNotFound(true)
            }
            return null;
          }
        else {    
            console.log(response.data)

            if (video == null) {
                setVideo(response.data.fireURL)
            }
            if (notfound) {
                setNotFound(false)
            }
        }
      
    });
  }, []);

  if (notfound) {
    return (
        <h2 className='not-found'>Page Not Found</h2>
    )
  }

  if (video !== null) {
    return (
        <ShortPlayer fireURL={video} />
    );
  }   
  else {
    return (
        <SearchingLoading />
      );
  }

  

  
}

export default VideoRoute;
