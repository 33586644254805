

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './VideoHome.css'
import QuestionHeader from './question/QuestionHeader';
import Loading from './Loading';
import videoface from '../Videos/face.mp4'
import qrvideo from '../Videos/qrsal.mov'
import vids from '../Videos/choc.mp4'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function VideoHome() {

    const PopupExample = () => (
        <Popup trigger={<button>Trigger</button>} position="top left">
            {close => (
            <div>
                Content here
                <a className="close" onClick={close}>
                &times;
                </a>
            </div>
            )}
        </Popup>
      );

    const styles = {
        section: {
            borderRadius: '12% / 6%', 
            width: '153.583px', 
            height: '339.391px', 
            transform: 'translateY(49.5px) translateZ(0px)'
        },

        sectiontest: {
            borderRadius: '0px', 
            width: '600px', 
            height: '600px', 
            transform: 'translateY(-42px) translateZ(0px)'
        },

        moveDes: {
            transform: 'translateY(500px) translateZ(0px)'
        },
        normal: {
            width: '400px',
            borderRadius: '20px',
            height: '556px',
            // transform: 'translateY(150.5px) translateZ(0px)',
            position: 'absolute',
            top: '280px'
        }
        
      };

    const playButton = () => {
        alert('Good')
    }

    useEffect(() => {
        document.querySelector('.video-tag-home').muted = true;
        document.querySelector('.video-tag-home').defaultMuted  = true;
        document.querySelector('.video-tag-home').play()
    }, [])
    
  return (
    <div className='video-home-container'>
        <div className='video-home-main'>
            <div className='video-home-page-inner'>
            {/* <div className='video-hand-container'>
                            <div className='button-fit-content'>
                                <button onClick={playButton} className='video-home-play-button'>
                                <svg viewBox="0 0 90 90" fill="none"><path fill="#ffffff" opacity="0.3" d="M45 90C69.8529 90 90 69.8527 90 44.9999C90 20.1471 69.8529 0 45 0C20.1472 0 0 20.1471 0 44.9999C0 69.8527 20.1472 90 45 90Z"></path><path fill="#ffffff" d="M45 85C67.0914 85 85 67.0913 85 44.9999C85 22.9086 67.0914 5 45 5C22.9086 5 5 22.9086 5 44.9999C5 67.0913 22.9086 85 45 85Z"></path><path d="M35 33.268V56.732C35 58.5212 37.0582 59.6083 38.6432 58.6344L57.8999 46.9025C59.3667 46.0192 59.3667 43.9808 57.8999 43.0749L38.6432 31.3656C37.0582 30.3917 35 31.4788 35 33.268Z" fill="black" opacity="0.7"></path></svg>
                                </button>
                            </div>
            </div> */}
                <div className='video-hand-container'>
                    <div style={styles.normal} className='home-page-video-transform'>
                        <div style={{transform: 'translateY(0px) scale(0.414251) translateZ(0px)'}} className='video-hand-container'>
                            <video src={vids} className='video-tag-home' width='1920' height='1080' playsinline  autoplay="autoplay" muted loop/>
                            <Link to='/disclaimer'>
                            <h2 className='disclaimer'>Disclaimer - This is a Publicly available Entertaining Product Video and NOT our own work</h2>
                            </Link>
                        </div>
                        
                    </div>
                </div>
                {/* <div style={{transform: 'translateY(49.5px) scale(0.571365) translateZ(0px)'}} className='video-hand-container'>
                    <img className="video-hand-image" src='https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/ttep/static/build/_assets/frame-b98bf01422838bd81cb1.png' />
                </div> */}
                <header className='video-header-container'>
                    <h1 className='video-header-title'>We sell entertaining Product Videos</h1>
                    <div  className='video-move-des'>
                    <h2 style={{marginBottom: '0px', marginTop: '10px'}} className="video-header-description">Get your Free Video trial today.</h2>
                    <h2 className="video-header-description">No credit card required.</h2>
                    
                    {/* <h2 className='disclaimer'><PopupExample /></h2> */}
                    <Link to='/seller'>
                        
                    <button className='start-now-button choose-creator-cta'>
                                <p font-size="3" color="#fff" class="start-now-text mobile-view-text">Get Started</p>
                                <svg className='arrow-creator mobile-arrow-video' width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.25 14H9L16 7L9 0H6.25L12.25 6H0V8H12.25L6.25 14Z" fill="black"/></svg>
                    </button>
                    </Link>
                    </div>
                </header>
            </div>
        </div>
    </div>
  );
}

export default VideoHome;
