

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css'
import QuestionHeader from './question/QuestionHeader';
import Loading from './Loading';

function TextHome() {
    
  return (
    <main className='main-content'>
            <div className='homepage-container'>
                <div className='home-page-mobile'></div>
                <div className='home-page-desktop'>
                    <section className='hero-main'>
                        <div className='hero dark-theme-style'>
                            <div className='hero-headline'>
                                <h1 className='headline-h1'>
                                    <span className='headline-span'>
                                        We sell entertaining Product Videos
                                        {/* <span className='headlien-italic'>to</span> */}
                                        
                                    </span>
                                </h1>
                                <p className="mobile-home-description"><span>Get your free videos now.</span></p>
                                <p className='mobile-home-description'><span>No credit card required.</span></p>
                            </div>
                            <div className='hero-cta-container'>
                                <Link to='/seller'>
                                <div className='hero-cta-button'>
                                    <span className='hero-button-text'> Get Started </span>
                                </div>
                                </Link>
                                <p className='cta-description'>
                                    <span>Get your free video trial today.</span>
                                    <span>No credit card required.</span>
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
    </main>
  );
}

export default TextHome;
