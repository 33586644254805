

import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Pricing.css'
import QuestionHeader from './question/QuestionHeader';
import Loading from './Loading';
import { LoginContext } from '../Contexts/LoginContext';

import axios from "axios";

function Pricing() {

    const [waitLoad, setWaitLoad] = useState(false);
    const location = useLocation();
    const userResigered = location.state && location.state.registrationQuery; 
    // const {backendData,setBackendData} = useContext(LoginContext)

    const [orderLoading, setOrderLoading] = useState(false);

    const tourl = 'ordered'

    const standardPricing = [
       
        "Live-model included",
        
        "Script writing",
        "Voice over recording",
        "Multi-platform optimization"
      ]

    const proPricing = [
        
        "Live-model included",
        
        "Script writing",
        "Voice over recording",
        "Multi-platform optimization"
    ]  

    const businessPricing = [
        "Product filming included",
        "Live-model included",
        "60 seconds running time",
        "Script writing",
        "Voice over recording",
        "4K Video",
        "Multi-platform optimization"
    ]

    const singlePlan = (e) => {

        console.log(userResigered)
        Object.assign(userResigered, {plan: "1 video"});
        if(!orderLoading) {
            setOrderLoading(true)
        }
        if (!waitLoad) {

            axios.post('https://the-webbing-402113.el.r.appspot.com/api/ordernow', userResigered)
            .then((response) => {
                console.log('Succesful')
                console.log('pricing')


                const creator = document.querySelector('.page-container-margin');
                const header_transition = document.querySelector('.pricing-head-main');
                if (header_transition) {
                    header_transition.style.opacity = 0;
                }
                if(creator) {
                    
                    creator.style.opacity = 0;
                    creator.style.transform = 'translateY(20px)';
                }
                if(orderLoading) {
                    setOrderLoading(false)
                }

                setTimeout(() => {
                    setWaitLoad(true)
                }, 550)
                
            })
            .catch((error) => {
            
                console.error(error);
            });
            
            

            //setWaitLoad(true)
        }
    }


    const doublePlan = (e) => {

        console.log(userResigered)
        Object.assign(userResigered, {plan: "2 or more videos"});
        if(!orderLoading) {
            setOrderLoading(true)
        }
        if (!waitLoad) {

            axios.post('https://the-webbing-402113.el.r.appspot.com/api/ordernow', userResigered)
            .then((response) => {
                console.log('Succesful')
                console.log('pricing')

                const creator = document.querySelector('.page-container-margin');
                const header_transition = document.querySelector('.pricing-head-main');
                if (header_transition) {
                    header_transition.style.opacity = 0;
                }
                if(creator) {
                    
                    creator.style.opacity = 0;
                    creator.style.transform = 'translateY(20px)';
                }
                if(orderLoading) {
                    setOrderLoading(false)
                }

                setTimeout(() => {
                    setWaitLoad(true)
                }, 550)
                
            })
            .catch((error) => {
            
                console.error(error);
            });
            
            

            //setWaitLoad(true)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            const creator = document.querySelector('.page-container-margin');
            
            if(creator) {
                
                creator.style.opacity = 1;
                creator.style.transform = 'translateY(0px)';
                
            }
    
        }, 100);
        }, []); 

    if (waitLoad) {
        return (
            <Loading tourl={tourl}/>
        );
        }      
    
  return (
    <div>
        <main className='questionaire-main transition-pricing'>
            <div className='pricing-head-main'><QuestionHeader /></div>
            
            <div className='question-section-page'>
                <div className='page-container-question'>
                    <div className='page-container-margin'>
                        <div style={{paddingBottom: '50px'}}className='page-container-display'>
                            <div className='page-container-padding'>
                                <div className='page-pricing-gird page-pricing-main'>
                                  <div className='pricing-header-container'>
                                      <h2 className='pricing-title'>Guaranteed Quality Product Video</h2>
                                      {/* <div className='pricing-description'>videos are one-time purchase. You get all the features in every plan</div> */}
                                  </div>

                                  <div className='pricing-price-container'>
                                    <div className='pricing-individual-container'>
                                        <div className='pricing-dollar-container'>
                                            <div className='pricing-option-text'>Per Product Video</div>
                                            <div className='pricing-amount-text'>Rs.1,100</div>
                                            <div className='pricing-licence-text'>billed monthly (First month free)</div>
                                        </div>
                                        <div className='pricing-features-container'>
                                            {standardPricing.map((feature, featureIndex) => {
                                                return (
                                                    <div className='pricing-feature-block'>
                                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 12a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0zM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.53 7.28a.75.75 0 00-1.06-1.06L11 12.94l-1.47-1.47a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l5-5z" fill="currentColor"></path></svg>
                                                        <strong>{feature}</strong>
                                                    </div>    
                                                )
                                            })}
                                            
                                        </div>
                                        <button onClick={singlePlan} class={"start-now-button choose-creator-cta" + (orderLoading ? 'order-loading' : '')}><p font-size="3" color="#fff" class="start-now-text">{orderLoading ? 'Processing' : 'Start your free trial'}</p></button>
                                    </div>

                                    <div className='pricing-individual-container best-pricing-active'>
                                        <div className='pricing-dollar-container'>
                                            <div className='pricing-option-text'>Per Product Video</div>
                                            <div className='pricing-amount-text'>Rs.11,800</div>
                                            <div className='pricing-licence-text'>billed annually (30 day money back guarantee)</div>
                                        </div>
                                        <div className='pricing-features-container'>
                                            {proPricing.map((feature, featureIndex) => {
                                                return (
                                                    <div className='pricing-feature-block'>
                                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 12a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0zM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.53 7.28a.75.75 0 00-1.06-1.06L11 12.94l-1.47-1.47a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l5-5z" fill="currentColor"></path></svg>
                                                        <strong>{feature}</strong>
                                                    </div>    
                                                )
                                            })}
                                            
                                        </div>
                                        <button onClick={doublePlan} class={"start-now-button choose-creator-cta" + (orderLoading ? 'order-loading' : '')}><p font-size="3" color="#fff" class="start-now-text">{orderLoading ? 'Processing' : 'Order now'}</p></button>
                                    </div>

                                    {/* <div className='pricing-individual-container'>
                                        <div className='pricing-dollar-container'>
                                            <div className='pricing-option-text'>Business</div>
                                            <div className='pricing-amount-text'>Rs.5,000</div>
                                            <div className='pricing-licence-text'>pay-once licence</div>
                                        </div>
                                        <div className='pricing-features-container'>
                                            {businessPricing.map((feature, featureIndex) => {
                                                return (
                                                    <div className='pricing-feature-block'>
                                                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 12a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0zM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.53 7.28a.75.75 0 00-1.06-1.06L11 12.94l-1.47-1.47a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l5-5z" fill="currentColor"></path></svg>
                                                        <strong>{feature}</strong>
                                                    </div>    
                                                )
                                            })}
                                            
                                        </div>
                                        <button class="start-now-button choose-creator-cta"><p font-size="3" color="#fff" class="start-now-text">Get Started</p></button>
                                    </div> */}

                                  </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </main>    
    </div>
  );
}

export default Pricing;
