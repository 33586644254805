
import './NextSeller.css';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ButtonOpacity } from '../../../Contexts/ButtonOpacity';
import { LoginContext } from '../../../Contexts/LoginContext';

function NextSeller({ onPreviousClicked, onNextClicked}) {

  const {isAllInfo, setIsAllInfo} = useContext(ButtonOpacity)
  const {saving, setSaving} = useContext(LoginContext)

  return (
    <div className='page-next-container'>
        <button type='button' onClick={onNextClicked} className={'next-comp-btn' + (!isAllInfo ? ' no-proceed' : '') + (saving ? ' saving-load' : '')}>
            <p className="next-comp-txt">{!saving ? 'Next' : 'Saving..'}</p>
            {!saving && <svg fill="currentColor" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg" className="next-comp-arrow"><path d="M9.25 18H12l7-7-7-7H9.25l6 6H3v2h12.25l-6 6z"></path></svg>}
            
        </button>
        <button type='button' onClick={onPreviousClicked} className='comp-back-button-inner'>
            <svg fill="currentColor" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg" class="back-arrow"><path d="M12.75 18H10l-7-7 7-7h2.75l-6 6H19v2H6.75l6 6z"></path></svg>
        </button>
    </div>
  );
}

export default NextSeller;
