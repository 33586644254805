
import './Seller.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CreatorName from '../creator/pages/CreatorName';
import SellerStarted from './SellerStarted';
import CreatorCategory from '../creator/pages/CreatorCategory';
import CreatorTime from '../creator/pages/CreatorTime';
import Loading from '../Loading';
import { LoginContext } from '../../Contexts/LoginContext';

import axios from "axios";
import Pricing from '../Pricing';

function Seller() {

  // Name 
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  
  //Category
  const [checkedList, setCheckedList] = useState([]);
  const [checkedListTwo, setCheckedListTwo] = useState([]);
  const [chooseCategories, setChooseCategories] = useState([]);

  //TimeLine
  const [radioActive, setRadioActive] = useState(null);

  const [pageIndex, setPageIndex] = useState(0);
  const [waitLoad, setWaitLoad] = useState(false);
  const [finaValues, setFinalValues] = useState([])
  const [backendData, setBackendData] = useState(null);

  const [saving, setSaving] = useState(false)

  const timeLineOptions = [
    "In Less than 24 hours",
    "Within 3 working days",
    "No Rush",
  ]

  const handleNextClick = () => {
    setPageIndex(pageIndex + 1);
  };

  const previousBtnClicked = () => {
    setPageIndex(pageIndex - 1);
  };

  const pull_data = (data) => {
    console.log(data);
  }

  const nextBtnClicked = () => {
    if(pageIndex == 3) {
        const progress_container = document.querySelector('.container-inner-position');
        if (!saving) {
            setSaving(true)
        }
        const nodeValues = {
            firstName,
            lastName,
            email,
            phoneNumber,
            domain,
            chooseCategories,  
            time: timeLineOptions[radioActive - 1]
        }

        setBackendData(nodeValues)
        console.log(nodeValues)

        axios.post('https://the-webbing-402113.el.r.appspot.com/api/mongo', nodeValues)
            .then((response) => {
                console.log('Succesful')
                console.log('placed')
                if (saving) {
                    setSaving(false)   
                }
                if(progress_container) {
                    progress_container.style.opacity = 0;
                    // progress_container.addEventListener('transitionend', () => {
                    //     if(!waitLoad) {
                    //         setWaitLoad(true);
                    //     }
                    //     progress_container.removeEventListener('transitionend', () => {});
                    //   });
                    setTimeout(() => {
                        if(!waitLoad) {
                           setWaitLoad(true);
                        }
                    }, 1000);

                }
            })
            .catch((error) => {
            
                console.error(error);
            });
    }
    else {
        setPageIndex(pageIndex + 1);
    }
    
  };

  const handleButtonClick = (event) => {
    event.preventDefault()
    const dataValue = event.currentTarget.getAttribute('data-custom');
    const numericValue = parseFloat(dataValue); // Parse the string to a float
    
    setPageIndex(numericValue)
  };

  if (waitLoad) {
    return (
        <LoginContext.Provider value={{backendData, setBackendData}}>
        <Loading />
        </LoginContext.Provider>
    );
  }

  const testMe = () => {
    console.log(finaValues)
  }

  return (
    <div>
        <main className='seller-main-container'>
            <div className='seller-container'>
                <div className='container-inner-position'>
                    <div className='seller-logo-block'>
                        <div className='logo-seller-link'>
                            <Link to='/'>
                            <div className='seller-logo-svg-container'>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="3.43643" height="10.7807" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 7.59351 4.13037)" fill="white"/><rect width="3.43643" height="7.00837" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 29.5962 18.2087)" fill="white"/><rect width="3.43643" height="22.0435" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 20.5806 0)" fill="white"/><rect width="3.43643" height="28.8563" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 29.5439 9.06079)" fill="white"/><rect width="3.43643" height="18.0743" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 26.124 3.39783)" fill="white"/><rect width="3.43643" height="10.7137" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 11.0027 18.729)" fill="white"/><ellipse cx="11.9565" cy="2.00383" rx="1.97485" ry="2.00383" fill="white"/><ellipse cx="2.59741" cy="20.6482" rx="1.97485" ry="2.00383" fill="white"/><ellipse cx="20.543" cy="29.709" rx="1.97485" ry="2.00383" fill="white"/></svg>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className='seller-page-block'>
                        <div className='seller-page-margin'>
                            <div className='seller-grid-container'>
                            
                                <div className='steps-grid-seller'>
                                    <div className='steps-grid-seller-inner'>
                                        <button onClick={handleButtonClick} data-custom="1" className={'desktop-step-1-container' + (pageIndex == 1 ? " active-step" : "")}>
                                            <div className='about-you-1'>
                                                <span className='text-01-steps'>01</span>
                                                <span className='text-about-you-steps'>About You</span>
                                            </div>
                                            <div height="2px" width="100%" opacity="0.8" className={"underline-step" + (pageIndex == 1 ? " active-underline" : "")}><div height="2px" width="0" opacity="0.8" className="underline-progress-bar-step"></div></div>
                                        </button>
                                        <button onClick={handleButtonClick} data-custom="2" className={'desktop-step-1-container' + (pageIndex == 2 ? " active-step" : "")}>
                                            <div className='about-you-1'>
                                                <span className='text-01-steps'>02</span>
                                                <span className='text-about-you-steps'>Categories</span>
                                            </div>
                                            <div height="2px" width="100%" opacity="0.8" className={"underline-step" + (pageIndex == 2 ? " active-underline" : "")}><div height="2px" width="0" opacity="0.8" className="underline-progress-bar-step"></div></div>
                                        </button>
                                        <button onClick={handleButtonClick} data-custom="3" className={'desktop-step-1-container' + (pageIndex == 3 ? " active-step" : "")}>
                                            <div className='about-you-1'>
                                                <span className='text-01-steps'>03</span>
                                                <span className='text-about-you-steps'>Finish</span>
                                            </div>
                                            <div height="2px" width="100%" opacity="0.8" className={"underline-step" + (pageIndex == 3 ? " active-underline" : "")}><div height="2px" width="0" opacity="0.8" className="underline-progress-bar-step"></div></div>
                                        </button>
                                    </div>
                                </div>
                                <div className='divider-grid-seller'></div>
                                <div className='question-grid-seller'>
                                    <LoginContext.Provider value={{
                                        finaValues, 
                                        setFinalValues, 
                                        firstName, 
                                        setFirstName, 
                                        lastName,
                                        setLastName,
                                        email,
                                        setEmail,
                                        phoneNumber,
                                        setPhoneNumber,
                                        domain,
                                        setDomain,

                                        checkedList,
                                        setCheckedList,
                                        checkedListTwo,
                                        setCheckedListTwo,
                                        chooseCategories,
                                        setChooseCategories,

                                        radioActive,
                                        setRadioActive,

                                        saving,
                                        setSaving,

                                        backendData,
                                        setBackendData
                                    }}>

                                    
                                    
                                    {pageIndex == 0 && <SellerStarted onNextClick={handleNextClick}/>}
                                    {pageIndex == 1 && <CreatorName onNextClicked={nextBtnClicked} onPreviousClicked={previousBtnClicked}/>}
                                    {pageIndex == 2 && <CreatorCategory onNextClicked={nextBtnClicked} onPreviousClicked={previousBtnClicked}/>}
                                    {pageIndex == 3 && <CreatorTime onNextClicked={nextBtnClicked} onPreviousClicked={previousBtnClicked}/>}
                                    
                                    </LoginContext.Provider>
                                </div>
                                <div className='bottom-divider-grid-seller'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
  );
}

export default Seller;
