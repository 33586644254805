import React, { useState } from 'react'
import './Footer.css'
import { Link, useNavigate } from 'react-router-dom';

function Footer() {

    return (
      <footer className='main-footer footer-container'>
        <div></div>
        <div>
            <span className='footer-element-title'>Vuekart</span>
            <div className='footer-element-link'>© 2023</div>
        </div>
        <div>
            <span className='footer-element-title'>Updates</span>
            <Link to='/about'>
            <div className='footer-element-link'>About us</div>
            </Link>
            <div className='footer-element-link'>Blog</div>
        </div>
        <div>
            <span className='footer-element-title'>Resources</span>
            <div className='footer-element-link'>Contact</div>
            
        </div>

        <div>
            <span className='footer-element-title'>Company</span>
            <Link to='/terms'>
            <div className='footer-element-link'>Terms of Use</div>
            </Link>
            <Link to='/privacy'>
            <div className='footer-element-link'>Privacy Policy</div>
            </Link>
        </div>
      </footer>
    );
  }
  
  export default Footer;