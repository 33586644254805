
import './Option.css';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Option() {

  useEffect(() => {
        setTimeout(() => {
            const titleTrans = document.querySelectorAll('.learn-more-title');
            const buttonTrans = document.querySelectorAll('.learn-more-button-container');
            if(titleTrans) {
                titleTrans.forEach(element => {
                    element.style.opacity = 1;
                  });
            }
            if(buttonTrans) {
                buttonTrans.forEach(element => {
                    element.style.opacity = 1;
                  });
            }
        }, 100);
    }, []);  
    
  return (
    <div className='choose-section'>
        <div className='onboarding-1'>
            <div className='option-container'>
                <div className='learn-more-section seller-signup-choose'>
                    <div className='learn-more-title'>
                        <h1 className='learn-more-h1'>
                            <span className='span-learn'>
                                Continue as Seller
                            </span>
                        </h1>
                    </div>
                    
                    <div className='learn-more-button-container'>
                        <button className='start-now-button choose-seller-cta'>
                            <p font-size="3" color="#fff" class="start-now-text start-text-seller-white">Get Started</p>
                            <svg className='arrow-seller' width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.25 14H9L16 7L9 0H6.25L12.25 6H0V8H12.25L6.25 14Z" fill="white"/></svg>
                        </button>
                    </div>
                </div>
                
                <div className='learn-more-section creator-signup-choose'>
                    <div className='learn-more-title'>
                            <h1 className='learn-more-h1 choose-hero-creator'>
                                <span className='span-learn'>
                                    Continue as Creator
                                </span>
                            </h1>
                    </div>
                        
                    <div className='learn-more-button-container'>
                        <Link to='/creator'>
                            <button className='start-now-button choose-creator-cta'>
                                <p font-size="3" color="#fff" class="start-now-text">Get Started</p>
                                <svg className='arrow-creator' width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.25 14H9L16 7L9 0H6.25L12.25 6H0V8H12.25L6.25 14Z" fill="black"/></svg>
                            </button>
                        </Link>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Option;
