import React, { useEffect, useState, useRef   } from 'react';
import './AdminUpload.css'; // We'll create this CSS file in the next step
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { ref, uploadBytesResumable, getDownloadURL } from '@firebase/storage';
import { storage } from '../config/firebase';

const chunkSize = 10 * 1024;

const AdminUpload = () => {
    const navigate = useNavigate();

    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [dropzoneActive, setDropzoneActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
    const [currentFileIndex, setCurrentFileIndex] = useState(null);
    const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
    const [isLoading, setIsLoading] = useState();
    const [url, setUrl] = useState();
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);

    const [copied, setCopied] = useState(false);
    const [success, setSuccess] = useState(false)
    const [copiedValue, setCopiedValue] = useState(null)

    function handleDrop(e) {
        e.preventDefault();
        setFiles([...files, ...e.dataTransfer.files]);
    }

    const handleFileChange = (e) => {
          e.preventDefault();
          // You can perform actions with the selected file here
          const file = e.target.files[0];
          setSelectedFile(file);

          if (!file) return;
          setLoading(true);
          const storageRef = ref(storage, `${file.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on("state_changed", (snapshot) => {
              var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              setProgress(progress);
          }, (err) => {
              console.log(err);
              setLoading(false);
          },
              () => {
                  getDownloadURL(uploadTask.snapshot.ref)
                      .then(url => {
                          setUrl(url);
                          setLoading(false);
                          console.log(url)
                          axios.post('https://the-webbing-402113.el.r.appspot.com/api/adminsave', { url })
                            .then((response) => {
                            // Handle the response from the backend, if needed
                                console.log(response)
                                console.log(`unique ID = ${response.data.searchURL}`)
                                setCopiedValue(response.data.videoURL)
                                if (!success) {
                                    setSuccess(true)
                                }
                                //navigate("/finish", { state: { responseQR: response.data.qrCodeImage,  responseVideoURL: response.data.videoURL } });
                            })
                            .catch((error) => {
                            // Handle any errors, if they occur
                            console.error(error);
                            });
                      })
              }
          )
        
      };

    const showToastMessage = () => {
        if (!copied) {
            setCopied(true);
        }
        setTimeout(() => {
            
               setCopied((prevShow) => {
                if (prevShow) {
                    return false;
                }
                return prevShow;
                });
            
            
        }, 1000);
    };
    

    useEffect(() => {
        // Add a delay to ensure the transition starts after rendering
        setTimeout(() => {
            const container_upload = document.querySelector('.container-upload');
            if (container_upload) {
                container_upload.style.opacity = 1;
            }
        }, 100);
      }, []);
  return (
    <div className='main-page-upload'>
        <div className='container-upload'>
            <div className='empty-state'>
                <h1 className="hero-title"><span class="hero-title-span">Upload The Video</span></h1>
                {/* <p className='short-description'>Generate a free QR Code for your video that would redirect your customers to your video manual</p>
                <p className='short-description'>You can Print the QR Code on Packaging , Letter or simply share as a post</p> */}
                <div className='upload-video-section'>
                    <div 
                    onDragOver={e => {setDropzoneActive(true); e.preventDefault();}}
                    onDragLeave={e => {setDropzoneActive(false); e.preventDefault();}}
                    onDrop={e => handleDrop(e)}
                    className={"video-container" + (dropzoneActive ? " active" : "") + (loading ? ' center-loading' : '')}>
                        <div className={"video-card-body text-center" + (loading ? " upload-load" : "")}>
                            <div className='uplaod-logo-container'>
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" width="30" height="30" color="#5D647B" class="upload-svg"><path d="M16 16L12 12L8 16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 12V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.39 18.39C21.3653 17.8583 22.1358 17.0169 22.5799 15.9986C23.0239 14.9804 23.1162 13.8432 22.8422 12.7667C22.5682 11.6902 21.9435 10.7355 21.0667 10.0535C20.1899 9.3714 19.1109 9.00075 18 9.00001H16.74C16.4373 7.82926 15.8732 6.74235 15.09 5.82101C14.3067 4.89967 13.3249 4.16786 12.2181 3.68062C11.1114 3.19338 9.90856 2.96337 8.70012 3.0079C7.49169 3.05242 6.30907 3.37031 5.24118 3.93768C4.17329 4.50505 3.24792 5.30712 2.53463 6.2836C1.82134 7.26008 1.3387 8.38555 1.12299 9.57541C0.907276 10.7653 0.964111 11.9885 1.28922 13.1533C1.61433 14.318 2.19925 15.3939 3.00001 16.3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16 16L12 12L8 16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                            </div>
                            <div className='upload-button-container'>
                                
                                <input
                                    type="file"
                                    
                                    accept="video/*" // Set accepted file types to only allow video files
                                    style={{ display: 'none' }}
                                    ref={fileInputRef }
                                    onChange={handleFileChange}
                                />

                                <button onClick={() => fileInputRef.current.click()} className='upload-button-main'>
                                    Upload Video
                                </button>
                                
                            </div>
                            <div className='drop-file-text'>
                                or drop a file
                            </div>
                        </div>
                        {success ? (
                            <div className={'copy-video'}>
                            {!copied ? (
                                <div className='copy-link' onClick={() => {navigator.clipboard.writeText(copiedValue); showToastMessage();}}>
                                <div className='icon-copy-main'>
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m19.409 10.955-.284.284-.284.284c-.318.318-.557.476-.796.476s-.478-.158-.795-.476c-.318-.317-.476-.556-.476-.795 0-.24.158-.478.476-.796l.284-.284.284-.284c.53-.53.795-1.06.795-1.591 0-.53-.265-1.06-.795-1.591-.53-.53-1.06-.796-1.591-.796-.53 0-1.06.266-1.591.796l-1.733 1.733-1.733 1.733c-.531.531-.77 1.07-.783 1.582-.013.511.201.996.577 1.418.27.304.402.534.395.76-.007.225-.153.447-.44.734-.31.31-.56.475-.808.483-.247.007-.492-.143-.791-.462-.886-.946-1.35-1.943-1.329-2.968.022-1.026.53-2.08 1.588-3.138l1.733-1.733 1.733-1.733C14.105 3.53 15.166 3 16.227 3c1.06 0 2.121.53 3.182 1.59C20.469 5.652 21 6.713 21 7.774c0 1.06-.53 2.121-1.591 3.182zm-6.327-1.097c-.288.287-.434.509-.44.734-.008.226.124.456.394.76.376.422.59.907.577 1.418-.012.512-.252 1.05-.783 1.582l-1.733 1.733-1.733 1.733c-.53.53-1.06.795-1.591.795-.53 0-1.06-.265-1.591-.795-.53-.53-.796-1.06-.796-1.591 0-.53.266-1.06.796-1.591l.284-.284.284-.284c.318-.318.476-.557.476-.796s-.158-.478-.476-.795c-.317-.318-.556-.476-.795-.476-.24 0-.478.158-.796.476l-.284.284-.284.284C3.531 14.105 3 15.166 3 16.227c0 1.06.53 2.121 1.591 3.182C5.651 20.469 6.712 21 7.773 21c1.06 0 2.121-.53 3.182-1.591l1.733-1.733 1.733-1.733c1.059-1.059 1.566-2.112 1.588-3.138.021-1.025-.443-2.022-1.329-2.968-.3-.32-.544-.47-.791-.462-.247.008-.497.173-.807.483z"></path></svg>
                                    
                                </div>
                                <div className='text-copy'>
                                        Copy Link
                                </div>
                            </div>
                            ) : (
                                <div style={{color: 'green'}} className='text-copy'>
                                        Copied!
                                </div>
                            )}
                            
                        </div>
                        ) : (
                            <div className={'paste-video' + (loading ? " upload-load" : "")}>
                            Paste video
                            <span className='ctrl-main'>ctrl</span>
                            +
                            <span className='ctrl-main'>v</span>
                        </div>    
                        )}
                        
                        <div className={'uploading-loading-text' + (loading ? " loading-visibile" : "")}>
                            <span>Uploading...</span>
                            <div className='progress'>
                                
                                <div className='progressbar' style={{width:progress+'%'}}></div>
                              </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default AdminUpload;