

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Privacy.css'
import LinkHeader from './question/LinkHeader';

function Disclaimer() {
    
  return (
    <>
        <LinkHeader />
        <div style={{maxHeight: '100%'}} className='about-page-container'>
            <div className='about-us-title-container'>
                <div className='about-us-title-inner about-us-tldr'>
                    <section className='about-title-section'>
                        <h1 className="about-title-main about-title-wrap">Disclaimer</h1>
                    </section>
                </div>
            </div>
            <div className='about-info-container info-padding'>
                <div className='info-container-inner'>
                    <article>
                        <p>
                        The information (Visual,Written and Audio) contained in this website is for general information purposes and for example use only. The information is provided by VueKart.com and while we endeavour to keep the information up to date, correct and free of copyright infringement, We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                        </p>
                        <br></br>
                        
                        <p>
                        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
Through this website you are able to link to other websites which are not under the control of VueKart. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
Every effort is made to keep the website up and running smoothly. However,VueKart.com takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.

                        </p>
                        
                    </article>
                </div>
            </div>
        </div>
        </>
  );
}

export default Disclaimer;
