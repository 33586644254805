import React, { useState } from 'react'
import './LinkHeader.css'
import { Link, useNavigate } from 'react-router-dom';

function LinkHeader() {
    const [drawOpened, setDrawOpened] = useState(false)

    const navigate = useNavigate()

    const newVaigate = (e) => {
        navigate('/about')
    }

    const guide = (e) => {
        navigate('/guide')
    }

    const openDrawer = () => {
        if(!drawOpened) {
            setDrawOpened(true)
        }
        else if (drawOpened) {
            setDrawOpened(false)
        }
    }

    return (
      <nav className='main-nav'>
        <section className='nav-mobile'>
        <header className='nav-mobile-header link-header-main'>
                <div className={!drawOpened ? 'nav-mobile-height-link' : 'drawer-opened-mobile-link'}></div>
                <div className='mobile-logo-container'>
                    <Link to='/'>
                    <div className='mobile-logo-link'>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="3.43643" height="10.7807" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 7.59351 4.13037)" fill="black"/><rect width="3.43643" height="7.00837" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 29.5962 18.2087)" fill="black"/><rect width="3.43643" height="22.0435" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 20.5806 0)" fill="black"/><rect width="3.43643" height="28.8563" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 29.5439 9.06079)" fill="black"/><rect width="3.43643" height="18.0743" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 26.124 3.39771)" fill="black"/><rect width="3.43643" height="10.7137" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 11.0027 18.729)" fill="black"/><ellipse cx="11.9565" cy="2.00383" rx="1.97485" ry="2.00383" fill="black"/><ellipse cx="2.59741" cy="20.6481" rx="1.97485" ry="2.00383" fill="black"/><ellipse cx="20.543" cy="29.7089" rx="1.97485" ry="2.00383" fill="black"/></svg>

                    </div>
                    </Link>
                </div>
                <ul className={!drawOpened ? 'mobile-nav-items-container' : 'mobile-nav-items-opened'}>
                    <li className='nav-item-list-item'>
                        
                        <button style={{color: '#000'}} onClick={guide} className="list-button-mobile">Seller</button>
                        
                    </li>
                    <li className='nav-item-list-item'>
                        
                        <button style={{color: '#000'}} onClick={newVaigate} className="list-button-mobile">About us</button>
                        
                    </li>
                </ul>
                <button onClick={openDrawer} className='open-navigation-mobile'>

                    <svg className={!drawOpened ? 'toggle-closed' : 'toggle-nav'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000" viewBox="0 0 24 24"><path d="M5 5L19 19M19 5L5 19" stroke="#000" stroke-width="2" stroke-linecap="round"></path></svg>
                    <svg className={!drawOpened ? 'toggle-nav' : 'toggle-closed'} height="24" viewBox="0 0 32 22" width="24" xmlns="http://www.w3.org/2000/svg" fill="#000"><g fill-rule="evenodd"><path d="M0 0h32v2H0zM0 10h32v2H0zM0 20h32v2H0z"></path></g></svg>
                </button>
            </header>
        </section>
        <section className='nav-desktop link-header-section'>
            <div className='top-menu link-header-main'>
                <div className='nav-container'>
                    <div className='nav-logo-container'>
                        <Link to='/'>
                        <div className='main-logo-link'>
                        <svg width="132" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="3.43643" height="10.7807" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 7.59351 4.13037)" fill="black"/>
<rect width="3.43643" height="7.00837" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 29.5962 18.2087)" fill="black"/>
<rect width="3.43643" height="22.0435" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 20.5806 0)" fill="black"/>
<rect width="3.43643" height="28.8563" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 29.5439 9.06079)" fill="black"/>
<rect width="3.43643" height="18.0743" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 26.124 3.39771)" fill="black"/>
<rect width="3.43643" height="10.7137" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 11.0027 18.729)" fill="black"/>
<ellipse cx="11.9565" cy="2.00383" rx="1.97485" ry="2.00383" fill="black"/>
<ellipse cx="2.59741" cy="20.6481" rx="1.97485" ry="2.00383" fill="black"/>
<ellipse cx="20.543" cy="29.7089" rx="1.97485" ry="2.00383" fill="black"/>
</svg>


                        </div>
                        </Link>
                    </div>
                    <div className='nav-items-nav-container'>
                        {/* <span className='nav-aligned-item creator-item'>Creator</span> */}
                        <span onClick={guide} className='nav-aligned-item seller-item'>Seller</span>
                        <span onClick={newVaigate} className='nav-aligned-item about-us-item'>About Us</span>
                    </div>
                    <div className='nav-auth-button-container'>
                        <Link to='/seller'>
                            <div className='nav-get-started'>
                                <span className='auth-button'>Get Started</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
      </nav>
    );
  }
  
  export default LinkHeader;