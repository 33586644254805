
import './Creator.css';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import QuestionHeader from '../question/QuestionHeader';
import CreatorName from './pages/CreatorName';
import CreatorCategory from './pages/CreatorCategory';
import CreatorMotive from './pages/CreatorMotive';

function Creator() {
    

    useEffect(() => {
        setTimeout(() => {
            const creator = document.querySelector('.page-container-margin');
            if(creator) {
                
                creator.style.opacity = 1;
                creator.style.transform = 'translateY(0px)';
                
            }
    
        }, 100);
    }, []); 

  return (
    <div>
        <main className='questionaire-main'>
            <QuestionHeader />
            
            <div className='question-section-page'>
                <div className='page-container-question'>
                    <div className='page-container-margin'>
                        <div className='page-container-display'>
                            <div className='page-container-padding'>
                                <div className='page-justify-main'>
                                    <div className='page-left-hero'>
                                        {/* <CreatorName /> */}
                                        <h1 className='page-easy-title'>
                                            Let's start by knowing you
                                        </h1>
                                        <Link to='/creator/about'>
                                        <button className='start-now-button'>
                                            <p font-size="3" color="#000000" class="start-now-text">Continue</p>
                                            <svg color="#000000" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg" className="arrow-start"><path d="M9.25 18H12l7-7-7-7H9.25l6 6H3v2h12.25l-6 6z"></path></svg>
                                        </button>
                                        </Link>
                                    </div>
                                    <div class="css-1y10m5m"></div>
                                    <div className='page-steps-container'>
                                        <div>
                                            <div className='vue-step-01-container'>
                                                <button className='desktop-step-1-container active-step'>
                                                    <div className='about-you-1'>
                                                        <span className='text-01-steps'>01</span>
                                                        <span className='text-about-you-steps'>About You</span>
                                                    </div>
                                                    <div height="2px" width="100%" opacity="0.8" class="underline-step active-underline"><div height="2px" width="0" opacity="0.8" class="underline-progress-bar-step"></div></div>
                                                </button>
                                                <button className='desktop-step-1-container'>
                                                    <div className='about-you-1'>
                                                        <span className='text-01-steps'>02</span>
                                                        <span className='text-about-you-steps'>Experience</span>
                                                    </div>
                                                    <div height="2px" width="100%" opacity="0.8" class="underline-step"><div height="2px" width="0" opacity="0.8" class="underline-progress-bar-step"></div></div>
                                                </button>
                                                <button className='desktop-step-1-container'>
                                                    <div className='about-you-1'>
                                                        <span className='text-01-steps'>03</span>
                                                        <span className='text-about-you-steps'>Your Projects</span>
                                                    </div>
                                                    <div height="2px" width="100%" opacity="0.8" class="underline-step"><div height="2px" width="0" opacity="0.8" class="underline-progress-bar-step"></div></div>
                                                </button>
                                            </div>
                                            {/* <div className='desktop-nav-steps'>
                                                <button className='desktop-step-1-container'>
                                                    <div className='about-you-1'>
                                                        <span className='text-01-steps'>01</span>
                                                        <span className='text-about-you-steps'>About You</span>
                                                    </div>
                                                </button>
                                                <button className='desktop-step-1-container'>
                                                    <div className='about-you-1'>
                                                        <span className='text-01-steps'>01</span>
                                                        <span className='text-about-you-steps'>About You</span>
                                                    </div>
                                                </button>
                                                <button className='desktop-step-1-container'>
                                                    <div className='about-you-1'>
                                                        <span className='text-01-steps'>01</span>
                                                        <span className='text-about-you-steps'>About You</span>
                                                    </div>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='page-progress-container'>
                    <div className='continue-progress'>
                        <div className='continue-progress-inner'>
                            <div className='continue-next-button-container'>
                                <button className='continue-next-button'>
                                    NEXT
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </main>
    </div>
  );
}

export default Creator;
