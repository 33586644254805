import React, { useState, useEffect } from 'react'
import './Home.css'
import Header from './Header.js';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TextHome from './TextHome';
import VideoHome from './VideoHome';
import audioFile from '../Videos/sunflower.mp3'; 


function Home() {

  // useEffect(() => {
  //   // const audio = new Audio(audioFile);

  //   // Play the audio when the component is mounted
  //   const audio = new Audio(audioFile);

  //   document.addEventListener('click', () => {
  //     audio.muted = false; // Unmute when user interacts
  //     audio.play();
  //   })
    

  // }, []);

    // useEffect(() => {
    //     setTimeout(() => {
    //         const hero_headline = document.querySelector('.hero-headline');
    //         const hero_cta_container = document.querySelector('.hero-cta-container');
    //         if (hero_headline) {
    //             hero_headline.style.opacity = 1;
    //         }
    //         if(hero_cta_container) {
    //             hero_cta_container.style.opacity = 1;
    //         }
    //     }, 100);
    // }, []);

    return (
      <div>
        <Header />
        {/* <TextHome /> */}
        <VideoHome />
        <Footer />
      </div>
    );
  }
  
  export default Home;