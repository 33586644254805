

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ShortVideo.css'
import QuestionHeader from './question/QuestionHeader';
import LinkHeader from './question/LinkHeader';
import videoface from '../Videos/face.mp4'
import ShortVideoHeader from './question/ShortVideoHeader';

function ShortVideo() {
    
  return (
    <>
     <ShortVideoHeader />
     <div className='short-video-main'>
        <div className='short-section-left'>
            <div className='short-section-left-inner'>
                <div className='short-item-container'>
                    <nav className='short-nav-sec'>
                        <ul className='short-ul'>
                            <li>
                                <div className='short-nav-list-item-container'>
                                <svg className='short-nav-svg' width="32" data-e2e="" height="32" viewBox="0 0 48 48" fill="rgba(254, 44, 85, 1)" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.9505 7.84001C24.3975 7.38666 23.6014 7.38666 23.0485 7.84003L6.94846 21.04C6.45839 21.4418 6.2737 22.1083 6.48706 22.705C6.70041 23.3017 7.26576 23.7 7.89949 23.7H10.2311L11.4232 36.7278C11.5409 38.0149 12.6203 39 13.9128 39H21.5C22.0523 39 22.5 38.5523 22.5 38V28.3153C22.5 27.763 22.9477 27.3153 23.5 27.3153H24.5C25.0523 27.3153 25.5 27.763 25.5 28.3153V38C25.5 38.5523 25.9477 39 26.5 39H34.0874C35.3798 39 36.4592 38.0149 36.577 36.7278L37.7691 23.7H40.1001C40.7338 23.7 41.2992 23.3017 41.5125 22.705C41.7259 22.1082 41.5412 21.4418 41.0511 21.04L24.9505 7.84001Z"></path></svg>
                                <span className='short-nav-title'>
                                    Product Videos
                                </span>
                                </div>
                            </li>
                            <li>
                                <div className='short-nav-list-item-container'>
                                <svg className='short-nav-svg' fill="rgba(22, 24, 35, 1)" width="1em" height="1em" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 37.4a13.4 13.4 0 1 0 0-26.8 13.4 13.4 0 0 0 0 26.8ZM40.5 24a16.5 16.5 0 1 1-33 0 16.5 16.5 0 0 1 33 0Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M27.13 27.18a2 2 0 0 0 .93-1.32l1.84-9.33a.6.6 0 0 0-.9-.63l-8.14 4.92a2 2 0 0 0-.92 1.32l-1.84 9.33c-.1.52.45.9.9.63l8.13-4.92Zm-5.04-.45 3.11-1.89.7-3.57-3.1 1.89-.7 3.57Z"></path></svg>
                                <span className='short-nav-title'>
                                    Explore
                                </span>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <div className='sec-brands'>
                    <nav className='short-nav-sec'>
                        <ul className='short-ul'>
                            <li>
                                <div className='short-nav-list-item-container'>
                                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 8C5 7.05719 5 6.58579 5.29289 6.29289C5.58579 6 6.05719 6 7 6H13.0633C13.5239 6 13.7542 6 13.9558 6.09441C14.1574 6.18882 14.3048 6.36576 14.5997 6.71963L17.933 10.7196C18.4432 11.3318 18.6983 11.638 18.6983 12C18.6983 12.362 18.4432 12.6682 17.933 13.2804L14.5997 17.2804C14.3048 17.6342 14.1574 17.8112 13.9558 17.9056C13.7542 18 13.5239 18 13.0633 18H7C6.05719 18 5.58579 18 5.29289 17.7071C5 17.4142 5 16.9428 5 16V8Z" stroke="#161823" stroke-width="2"/>
</svg>




                                <span className='short-nav-description'>
                                    Brands
                                </span>
                                </div>
                            </li>
                            <li>
                                <div className='short-nav-list-item-container'>
                                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 12L8 8C8 5.79086 9.79086 4 12 4V4C14.2091 4 16 5.79086 16 8L16 12" stroke="#161823" stroke-width="2" stroke-linecap="round"/>
<path d="M3.69435 12.6678C3.83942 10.9269 3.91196 10.0565 4.48605 9.52824C5.06013 9 5.9336 9 7.68053 9H16.3195C18.0664 9 18.9399 9 19.514 9.52824C20.088 10.0565 20.1606 10.9269 20.3057 12.6678L20.8195 18.8339C20.904 19.8474 20.9462 20.3542 20.6491 20.6771C20.352 21 19.8435 21 18.8264 21H5.1736C4.15655 21 3.64802 21 3.35092 20.6771C3.05382 20.3542 3.09605 19.8474 3.18051 18.8339L3.69435 12.6678Z" stroke="#161823" stroke-width="2"/>
</svg>

                                <span className='short-nav-description'>
                                    Products
                                </span>
                                </div>
                            </li>
                            <li>
                                <div className='short-nav-list-item-container'>
                                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7957 15 12" stroke="#161823" stroke-width="2" stroke-linecap="round"/>
<path d="M4.5 8.7111C4.5 8.10998 4.5 7.80941 4.58582 7.52598C4.67163 7.24255 4.83835 6.99247 5.1718 6.4923L5.31253 6.2812C5.89382 5.40927 6.18447 4.9733 6.62665 4.73665C7.06884 4.5 7.5928 4.5 8.64074 4.5H15.3593C16.4072 4.5 16.9312 4.5 17.3733 4.73665C17.8155 4.9733 18.1062 5.40927 18.6875 6.2812L18.8282 6.4923C19.1616 6.99247 19.3284 7.24255 19.4142 7.52598C19.5 7.80941 19.5 8.10998 19.5 8.7111V15.5C19.5 17.3856 19.5 18.3284 18.9142 18.9142C18.3284 19.5 17.3856 19.5 15.5 19.5H8.5C6.61438 19.5 5.67157 19.5 5.08579 18.9142C4.5 18.3284 4.5 17.3856 4.5 15.5V8.7111Z" stroke="#161823" stroke-width="2"/>
<path d="M4.5 9.5H19.5" stroke="#161823" stroke-width="2" stroke-linecap="round"/>
</svg>

                                <span className='short-nav-description'>
                                    Services
                                </span>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    </div>
                    <div className='sec-brands sec-info-main'>
                        <div className='info-sec'>
                            <span className='info-sec-title'>About</span>
                            <span className='info-sec-title'>Contact</span>
                            <span className='info-sec-title'>Blogs</span>
                            <span className='info-sec-title'>Seller</span>
                        </div>
                        <div className='info-sec'>
                            <span className='info-sec-title'>Help</span>
                            <span className='info-sec-title'>Terms</span>
                            <span className='info-sec-title'>Privacy</span>
                            
                        </div>
                        <span className="vuekart-copyright-text">© 2023 Vuekart</span>
                    </div>
                </div>
            </div>
        </div>
        <div className='short-section-right'>
            <div className='short-section-padding'>
                <div className='short-video-sec-main'>
                    <div className='short-video-center'>
                        <div className='short-part-video'>
                            <canvas width="56.25" height="100" class="short-video-canvas"></canvas>
                            <div className='short-video-part-container'>
                                <div className='short-video-control-video'>
                                    <div className='short-video-thumbnail'></div>
                                    <div className='short-video-tag-video'>
                                        <div style={{width: '100%', height: '100%'}}>
                                            <video src={videoface} className='video-main-short' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='short-part-account'>
                            <button className='short-account-button'>
                                <span className='short-account-button-icon'>
                                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.3 0C15.05 0 13.35 0.9 12 2.8C10.65 0.95 8.95 0.0500001 6.7 0.0500001C3 7.07805e-08 0 3.25 0 7.25C0 10.9 2.7 13.25 5.3 15.5C5.6 15.75 5.95 16.05 6.25 16.35L7.4 17.35C9.6 19.3 10.7 20.3 11.2 20.6C11.45 20.75 11.75 20.85 12 20.85C12.25 20.85 12.55 20.75 12.8 20.6C13.3 20.3 14.2 19.5 16.7 17.2L17.7 16.3C18.05 16 18.35 15.7 18.7 15.45C21.35 13.25 24 10.95 24 7.25C24 3.25 21 0 17.3 0Z" fill="black"/></svg>                           
                                </span>
                                <strong className='short-account-button-title'>697.4K</strong>
                            </button>
                            <button className='short-account-button'>
                                <span className='short-account-button-icon'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.306668 6.67063C0 7.81513 0 9.21008 0 12C0 14.7899 0 16.1849 0.306668 17.3294C1.13887 20.4352 3.5648 22.8611 6.67063 23.6933C7.81513 24 9.21008 24 12 24H18C20.8284 24 22.2426 24 23.1213 23.1213C24 22.2426 24 20.8284 24 18V12C24 9.21008 24 7.81513 23.6933 6.67063C22.8611 3.5648 20.4352 1.13887 17.3294 0.306668C16.1849 0 14.7899 0 12 0C9.21008 0 7.81513 0 6.67063 0.306668C3.5648 1.13887 1.13887 3.5648 0.306668 6.67063ZM7.00098 10.667C7.00098 10.1147 7.44869 9.66699 8.00098 9.66699H16.001C16.5533 9.66699 17.001 10.1147 17.001 10.667C17.001 11.2193 16.5533 11.667 16.001 11.667H8.00098C7.44869 11.667 7.00098 11.2193 7.00098 10.667ZM11.0029 16.0003C11.0029 15.448 11.4506 15.0003 12.0029 15.0003H16.0029C16.5552 15.0003 17.0029 15.448 17.0029 16.0003C17.0029 16.5526 16.5552 17.0003 16.0029 17.0003H12.0029C11.4506 17.0003 11.0029 16.5526 11.0029 16.0003Z" fill="black"/></svg>

                                </span>
                                <strong className='short-account-button-title'>2.4K</strong>
                            </button>
                            <button style={{marginBottom: '10px'}}className='short-account-button'>
                                <span className='short-account-button-icon'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 22C19.7445 22.0002 19.4987 21.9025 19.313 21.727L12 14.815L4.687 21.727C4.54499 21.8613 4.36676 21.951 4.17433 21.985C3.9819 22.0191 3.78371 21.996 3.60424 21.9187C3.42477 21.8414 3.2719 21.7131 3.16451 21.5499C3.05713 21.3866 2.99993 21.1954 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22Z" fill="black"/></svg>

                                </span>
                                <strong className='short-account-button-title'>12.8K</strong>
                            </button>
                            <div style={{width: '56px', height: '56px'}} className='short-account-logo'>
                                <span shape="circle" style={{width: '56px', height: '56px'}} className='short-account-span'>
                                    <img src="https://1000logos.net/wp-content/uploads/2017/02/Colors-of-the-HM-Logo.jpg" className='short-account-img' />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='short-section-padding'>
                <div className='short-video-sec-main'>
                    <div className='short-video-center'>
                        <div className='short-part-video'>
                            <canvas width="56.25" height="100" class="short-video-canvas"></canvas>
                            <div className='short-video-part-container'>
                                <div className='short-video-control-video'>
                                    <div className='short-video-thumbnail'></div>
                                    <div className='short-video-tag-video'>
                                        <div style={{width: '100%', height: '100%'}}>
                                            <video src={videoface} className='video-main-short' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='short-part-account'>
                            <button className='short-account-button'>
                                <span className='short-account-button-icon'>
                                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.3 0C15.05 0 13.35 0.9 12 2.8C10.65 0.95 8.95 0.0500001 6.7 0.0500001C3 7.07805e-08 0 3.25 0 7.25C0 10.9 2.7 13.25 5.3 15.5C5.6 15.75 5.95 16.05 6.25 16.35L7.4 17.35C9.6 19.3 10.7 20.3 11.2 20.6C11.45 20.75 11.75 20.85 12 20.85C12.25 20.85 12.55 20.75 12.8 20.6C13.3 20.3 14.2 19.5 16.7 17.2L17.7 16.3C18.05 16 18.35 15.7 18.7 15.45C21.35 13.25 24 10.95 24 7.25C24 3.25 21 0 17.3 0Z" fill="black"/></svg>                           
                                </span>
                                <strong className='short-account-button-title'>697.4K</strong>
                            </button>
                            <button className='short-account-button'>
                                <span className='short-account-button-icon'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.306668 6.67063C0 7.81513 0 9.21008 0 12C0 14.7899 0 16.1849 0.306668 17.3294C1.13887 20.4352 3.5648 22.8611 6.67063 23.6933C7.81513 24 9.21008 24 12 24H18C20.8284 24 22.2426 24 23.1213 23.1213C24 22.2426 24 20.8284 24 18V12C24 9.21008 24 7.81513 23.6933 6.67063C22.8611 3.5648 20.4352 1.13887 17.3294 0.306668C16.1849 0 14.7899 0 12 0C9.21008 0 7.81513 0 6.67063 0.306668C3.5648 1.13887 1.13887 3.5648 0.306668 6.67063ZM7.00098 10.667C7.00098 10.1147 7.44869 9.66699 8.00098 9.66699H16.001C16.5533 9.66699 17.001 10.1147 17.001 10.667C17.001 11.2193 16.5533 11.667 16.001 11.667H8.00098C7.44869 11.667 7.00098 11.2193 7.00098 10.667ZM11.0029 16.0003C11.0029 15.448 11.4506 15.0003 12.0029 15.0003H16.0029C16.5552 15.0003 17.0029 15.448 17.0029 16.0003C17.0029 16.5526 16.5552 17.0003 16.0029 17.0003H12.0029C11.4506 17.0003 11.0029 16.5526 11.0029 16.0003Z" fill="black"/></svg>

                                </span>
                                <strong className='short-account-button-title'>2.4K</strong>
                            </button>
                            <button style={{marginBottom: '10px'}}className='short-account-button'>
                                <span className='short-account-button-icon'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 22C19.7445 22.0002 19.4987 21.9025 19.313 21.727L12 14.815L4.687 21.727C4.54499 21.8613 4.36676 21.951 4.17433 21.985C3.9819 22.0191 3.78371 21.996 3.60424 21.9187C3.42477 21.8414 3.2719 21.7131 3.16451 21.5499C3.05713 21.3866 2.99993 21.1954 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22Z" fill="black"/></svg>

                                </span>
                                <strong className='short-account-button-title'>12.8K</strong>
                            </button>
                            <div style={{width: '56px', height: '56px'}} className='short-account-logo'>
                                <span shape="circle" style={{width: '56px', height: '56px'}} className='short-account-span'>
                                    <img src="https://1000logos.net/wp-content/uploads/2017/02/Colors-of-the-HM-Logo.jpg" className='short-account-img' />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
    </>
  );
}

export default ShortVideo;
