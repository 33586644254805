import React, { useState } from 'react'
import './ShortVideoHeader.css'
import { Link, useNavigate } from 'react-router-dom';

function ShortVideoHeader() {

    return (
      <nav className='short-video-header-nav'>
        <div className='short-header-between'>
            <div className='short-header-logo-container'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_90_2)">
<path d="M8.79566 5.35777L8.79565 5.35776C8.13186 4.67962 7.05191 4.67594 6.38351 5.34954L1.21048 10.5628C0.542088 11.2364 0.538354 12.3322 1.20215 13.0104L1.20215 13.0104C1.86594 13.6885 2.94589 13.6922 3.61429 13.0186L8.78732 7.8053C9.45571 7.1317 9.45945 6.03591 8.79566 5.35777Z" fill="black"/>
<path d="M30.7986 19.4369L30.7986 19.4369C30.1348 18.7587 29.0548 18.755 28.3864 19.4286L25.8705 21.9642C25.2021 22.6378 25.1984 23.7336 25.8622 24.4117L25.8622 24.4117C26.526 25.0898 27.6059 25.0935 28.2743 24.4199L30.7902 21.8844C31.4586 21.2108 31.4624 20.115 30.7986 19.4369Z" fill="black"/>
<path d="M21.783 1.22788L21.783 1.22788C21.1192 0.54974 20.0392 0.546059 19.3708 1.21966L6.26471 14.4277C5.59632 15.1013 5.59258 16.1971 6.25638 16.8753C6.92017 17.5534 8.00012 17.5571 8.66852 16.8835L21.7746 3.67542C22.443 3.00182 22.4468 1.90602 21.783 1.22788Z" fill="black"/>
<path d="M30.7459 10.2884L30.7458 10.2884C30.0821 9.61029 29.0021 9.60661 28.3337 10.2802L10.4289 28.3243C9.76054 28.9979 9.7568 30.0937 10.4206 30.7718C11.0844 31.45 12.1643 31.4537 12.8327 30.7801L30.7375 12.736C31.4059 12.0624 31.4096 10.9666 30.7459 10.2884Z" fill="black"/>
<path d="M27.3259 4.62535L27.3259 4.62534C26.6621 3.9472 25.5822 3.94352 24.9138 4.61712L14.6034 15.0077C13.935 15.6813 13.9313 16.7771 14.5951 17.4552C15.2589 18.1334 16.3388 18.1371 17.0072 17.4635L27.3176 7.07288C27.986 6.39928 27.9897 5.30348 27.3259 4.62535Z" fill="black"/>
<path d="M12.2048 19.9564L12.2048 19.9564C11.541 19.2783 10.4611 19.2746 9.79269 19.9482L4.66686 25.1139C3.99846 25.7875 3.99473 26.8833 4.65852 27.5614L4.65852 27.5614C5.32231 28.2396 6.40227 28.2433 7.07066 27.5697L12.1965 22.4039C12.8649 21.7303 12.8686 20.6345 12.2048 19.9564Z" fill="black"/>
<path d="M11.9563 4.00766C13.047 4.00766 13.9311 3.11051 13.9311 2.00383C13.9311 0.897145 13.047 0 11.9563 0C10.8656 0 9.98145 0.897145 9.98145 2.00383C9.98145 3.11051 10.8656 4.00766 11.9563 4.00766Z" fill="black"/>
<path d="M2.5979 22.6522C3.68858 22.6522 4.57275 21.755 4.57275 20.6484C4.57275 19.5417 3.68858 18.6445 2.5979 18.6445C1.50722 18.6445 0.623047 19.5417 0.623047 20.6484C0.623047 21.755 1.50722 22.6522 2.5979 22.6522Z" fill="black"/>
<path d="M20.5432 31.7127C21.6339 31.7127 22.5181 30.8156 22.5181 29.7089C22.5181 28.6022 21.6339 27.7051 20.5432 27.7051C19.4525 27.7051 18.5684 28.6022 18.5684 29.7089C18.5684 30.8156 19.4525 31.7127 20.5432 31.7127Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_90_2">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>

            </div>
            <div className='short-header-search-container'>
                <div className='short-header-search-relative'>
                    <form className='short-search-form'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" role="img" className="icon__search"><path d="M1.5 7.75C1.5 9.4076 2.15848 10.9973 3.33058 12.1694C4.50269 13.3415 6.0924 14 7.75 14C9.4076 14 10.9973 13.3415 12.1694 12.1694C13.3415 10.9973 14 9.4076 14 7.75C14 6.0924 13.3415 4.50269 12.1694 3.33058C10.9973 2.15848 9.4076 1.5 7.75 1.5C6.0924 1.5 4.50269 2.15848 3.33058 3.33058C2.15848 4.50269 1.5 6.0924 1.5 7.75V7.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12.814 12.8132L15.5 15.4999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        <input className="icon__input" type="text" name="q" placeholder="Search..." autocomplete="off" value=""></input>
                    </form>
                </div>
            </div>
            <div className='short-header-button-container'>
                <div className='nav-get-started'>
                    <span className='auth-button short__btn'>Get Started</span>
                </div>
            </div>
        </div>
      </nav>
    );
  }
  
  export default ShortVideoHeader;