
import './CreatorTime.css';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import NextSeller from '../../seller/pages/NextSeller';
import { ButtonOpacity } from '../../../Contexts/ButtonOpacity';
import { LoginContext } from '../../../Contexts/LoginContext';

function CreatorTime({onNextClicked, onPreviousClicked}) {

  const {radioActive, setRadioActive} = useContext(LoginContext)  

//   const [radioActive, setRadioActive] = useState(null);
  const [isAllInfo, setIsAllInfo] = useState(false)
  
  const handleRadioActive = (index) => {
    if (radioActive != index) {
        setRadioActive(index)
    }
  };

  if (radioActive !== null) {
    if (!isAllInfo) {
        setIsAllInfo(true)
    }
  }
  else {
    if (isAllInfo) {
        setIsAllInfo(false)
    }
  }

  useEffect(() => {
        setTimeout(() => {
            const creator = document.querySelector('.step-wrapper');
            if(creator) {
                
                creator.style.opacity = 1;
                creator.style.transform = 'translateY(0px)';
                
            }
    
        }, 100);
  }, []);   
    
  return (
    <form className='step-wrapper'>
        <div className='step-hero-wrapper'>
            <h1 className='step-main-title-progress'>What is your ideal timeline for receiving your Product Video</h1>
        </div>
        <div className='question-category-group'>
            <div className='time-frame-group'>
                <div className='time-frame-items'>
                    <label className='time-frame-label'>
                        <div className='time-frame-item-1'>
                            <div className='circular-checkbox-container'>
                                <div className='radio-box-time'>
                                    <input type="radio" class="radio-button-time" value="OPTION_1" onClick={() => {handleRadioActive(1)}} />

                                    <span className={'checkmark-radio' + (radioActive == 1 ? ' checked-true' : '')}></span>
                                </div>
                            </div>
                            <div className='option-1-text-container'>
                                <p class="option-title-para">In Less than 24 hours.</p>
                            </div>
                        </div>
                    </label>
                    <div className='category-item-underline'><div class="underline-progress-category"></div></div>
                </div>

                <div className='time-frame-items'>
                    <label className='time-frame-label'>
                        <div className='time-frame-item-1'>
                            <div className='circular-checkbox-container'>
                                <div className='radio-box-time'>
                                    <input type="radio" class="radio-button-time" value="OPTION_2" onClick={() => {handleRadioActive(2)}}/>

                                    <span className={'checkmark-radio' + (radioActive == 2 ? ' checked-true' : '')}></span>
                                </div>
                            </div>
                            <div className='option-1-text-container'>
                                <p class="option-title-para">Within 3 working days.</p>
                            </div>
                        </div>
                    </label>
                    <div className='category-item-underline'><div class="underline-progress-category"></div></div>
                </div>

                <div className='time-frame-items'>
                    <label className='time-frame-label'>
                        <div className='time-frame-item-1'>
                            <div className='circular-checkbox-container'>
                                <div className='radio-box-time'>
                                    <input type="radio" class="radio-button-time" value="OPTION_3" onClick={() => {handleRadioActive(3)}} />

                                    <span className={'checkmark-radio' + (radioActive == 3 ? ' checked-true' : '')}></span>
                                </div>
                            </div>
                            <div className='option-1-text-container'>
                                <p class="option-title-para">No Rush.</p>
                            </div>
                        </div>
                    </label>
                    <div className='category-item-underline'><div class="underline-progress-category"></div></div>
                </div>
            </div>
        </div>
        <ButtonOpacity.Provider value={{isAllInfo, setIsAllInfo}}>
            <NextSeller onNextClicked={onNextClicked} onPreviousClicked={onPreviousClicked}/>
        </ButtonOpacity.Provider>
    </form>
  );
}

export default CreatorTime;
