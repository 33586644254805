import React, { useState, useEffect, useContext } from 'react'
import './Loading.css'
import { Link, useNavigate } from 'react-router-dom';
import { LoginContext } from '../Contexts/LoginContext';

function Loading({tourl}) {
    const navigate = useNavigate();
    const {backendData,setBackendData} = useContext(LoginContext)

    useEffect(() => {
        
        setTimeout(() => {
            const loadingContainer = document.querySelector('.loading-container');
            if(loadingContainer) {
                 loadingContainer.style.opacity = 1;       
            }
    
        }, 100);    
        setTimeout(() => {
            navigate(`/${tourl ? tourl : 'pricing'}`, { state: { registrationQuery: backendData} })
        }, 3000);
      }, []);

    return (
      <div className='loading-container'>
        <svg className="loading-logo" width="50" height="50" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="3.43643" height="10.7807" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 7.59351 4.13037)" fill="white"/>
<rect width="3.43643" height="7.00837" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 29.5962 18.2087)" fill="white"/>
<rect width="3.43643" height="22.0435" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 20.5806 0)" fill="white"/>
<rect width="3.43643" height="28.8563" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 29.5439 9.06079)" fill="white"/>
<rect width="3.43643" height="18.0743" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 26.124 3.39783)" fill="white"/>
<rect width="3.43643" height="10.7137" rx="1.71821" transform="matrix(0.699507 0.714626 -0.704361 0.709842 11.0027 18.729)" fill="white"/>
<ellipse cx="11.9565" cy="2.00383" rx="1.97485" ry="2.00383" fill="white"/>
<ellipse cx="2.59741" cy="20.6482" rx="1.97485" ry="2.00383" fill="white"/>
<ellipse cx="20.543" cy="29.709" rx="1.97485" ry="2.00383" fill="white"/>
</svg>
      </div>
    );
  }
  
  export default Loading;