import React, { useState } from 'react'
import './About.css'
import './Howto.css'
import { Link } from 'react-router-dom';
import LinkHeader from './question/LinkHeader';

function Howto() {
    return (
        <>
        <LinkHeader />
        <div style={{maxHeight: '100%'}}className='about-page-container'>
            <div className='about-us-title-container'>
                <div className='about-us-title-inner about-us-tldr'>
                    <section className='about-title-section'>
                        <h1 className="about-title-main about-title-wrap">Want to Sell more-A Lot More?</h1>
                    </section>
                </div>
            </div>
            <div className='about-info-container info-padding'>
                <div className='info-container-inner'>
                <p>At Vuekart we provide Sellers with an Entertaining Product Video, that Helps create a deeper connection between the Product and Potential Customers, (1) Even before purchase. (2) Can be placed next to your existing purchase button/options. And (3) That can also serve customers after Purchase, as a point of reference to help them gain the most from their purchase, by continuing to watch the video as many times as they want.</p>
                <p>Also your Video will:</p>
                <p></p>
                <p>Build brand awareness: introduce a need for your product and make your brand look good.</p>
                <p>Generate leads or sales: convincing prospects to get in touch or buy.</p>
                <p>Explain specific features: educating existing customers to improve adoption and retention
</p>
                <p>Some Sellers have asked if they can Include ads in their Video to generate income from Views. To meet this request whilst making sure to sustain or improve a great customer journey. Vuekart has developed the service to allow (1) Charities and (2)TV Broadcaster and Cinema Programme Advertisement (ONLY) within a Product Video. These types of advertisements have proved to enhance Product Video quality, without dimensioning the user viewing experience.</p>
                <p>Enable Video to QR Code or url Link
Use our “Video Manual to QR code or url Link” technology that allows you to generate a QR code onto offline assets such as Packaging, Paperwork or even into Billboard, Magazine or Newspaper marketing campaigns,etc. Or paste the Url link anywhere your audience may be Online, to effortlessly connect your offline audience and your online audience directly to your “VueKart.com” Product Video”. 
Click the link to Learn more <a style={{textDecoration: 'underline', color: 'blue'}}href='https://www.youtube.com/watch?v=LKEL01Y_Ys8&t=39s'>https://www.youtube.com/watch?v=LKEL01Y_Ys8&t=39s</a></p>
                </div>
            </div>
        </div>
        </>
    );
  }
  
  export default Howto;