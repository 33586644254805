import React, { useState } from 'react'
import './About.css'
import { Link } from 'react-router-dom';
import LinkHeader from './question/LinkHeader';

function About() {
    return (
        <>
        <LinkHeader />
        <div className='about-page-container'>
            <div className='about-us-title-container'>
                <div className='about-us-title-inner about-us-tldr'>
                    <section className='about-title-section'>
                        <h1 className="about-title-main about-title-wrap">Our Mission</h1>
                    </section>
                </div>
            </div>
            <div className='about-info-container info-padding'>
                <div className='info-container-inner'>
                <p>VueKart was founded with the Belief that Entertaining Product Video will take the place of Photos, for Marketing the latest Products and Services to Customers</p>
                <p>We believe, Short Form Video Content of Entertaining Product Videos, delivers the best viewing experience for today's audience. Making shopping online at your favourite Stores and Marketplaces more entertaining and less of a hit or miss exercise.</p>
                <p></p>
                <p>With Interesting People On-screen, delivering compelling performances.  A VueKart Product Video connects each viewer to the Products and Services that they want to know more about, in a way that informs and entertains, both before and after a sale..</p>
                <p>And through a deeper and truer connection with the Products and Services Purchased, Customers are at least three times less likely to return Products/Cancel Subscriptions,etc. Because they truly know what they are buying.</p>
                <p>Our Goal at VueKart- Make Entertaining Product Videos that help People to buy what they really, really, WANT.</p>
                </div>
            </div>
        </div>
        </>
    );
  }
  
  export default About;