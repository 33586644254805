
import './CreatorCategory.css';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import NextSeller from '../../seller/pages/NextSeller';
import { ButtonOpacity } from '../../../Contexts/ButtonOpacity';
import { LoginContext } from '../../../Contexts/LoginContext';

function CreatorCategory({onNextClicked, onPreviousClicked}) {

  const {checkedList, setCheckedList, checkedListTwo, setCheckedListTwo, chooseCategories, setChooseCategories} = useContext(LoginContext)  

  const [isAllInfo, setIsAllInfo] = useState(false)

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [checked, setChecked] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
//   const [checkedList, setCheckedList] = useState([]);
//   const [checkedListTwo, setCheckedListTwo] = useState([]);
//   const [chooseCategories, setChooseCategories] = useState([]);


  const categoryOne = [
    "Consumer Electronics",
    "Fashion and Apparel",
    "Beauty and Cosmetics",
    "Food and Beverages",
    "Home and Decor",
    "Fitness and Wellness",
    "Travel and Adventure",
  ]

  const categoryTwo = [
    "Health and Medical",
    "Tech and Software",
    "Entertainment",
    "Toys and Games",
    "DIY and Crafts",
    "Pet Products",
    "Others",
  ]

  const handleCheckbox = (event) => {
    event.preventDefault()
    const dataValue = event.currentTarget.getAttribute('data-checkboxindex');
    const isChecked = event.currentTarget.getAttribute('data-isChecked');
    

    const numericValue = parseFloat(dataValue);
    const categoryToAppend = categoryOne[numericValue]

    if (isChecked == 'false') {
        const updatedCategoryChekbox = [...checkedList, numericValue];  
        const updatedCategoryChoice = [...chooseCategories, categoryToAppend];   
        setChooseCategories(updatedCategoryChoice)
        setCheckedList(updatedCategoryChekbox);

        console.log(categoryToAppend)
        
    }
    else if (isChecked == 'true') {
        const updatedNumbers = checkedList.filter((number) => number !== numericValue);
        const removeChoice = chooseCategories.filter((values) => values !== categoryToAppend);
        setCheckedList(updatedNumbers);
        setChooseCategories(removeChoice)

    } 
  };

  const handleCheckboxTwo = (event) => {
    event.preventDefault()
    const dataValue = event.currentTarget.getAttribute('data-checkboxindex');
    const isChecked = event.currentTarget.getAttribute('data-isChecked');

    const numericValue = parseFloat(dataValue);
    const categoryToAppend = categoryTwo[numericValue]

    if (isChecked == 'false') {
        const updatedCategoryChekbox = [...checkedListTwo, numericValue];    
        const updatedCategoryChoice = [...chooseCategories, categoryToAppend];   
        setChooseCategories(updatedCategoryChoice)
        setCheckedListTwo(updatedCategoryChekbox);
    }
    else if (isChecked == 'true') {
        const updatedNumbers = checkedListTwo.filter((number) => number !== numericValue);
        const removeChoice = chooseCategories.filter((values) => values !== categoryToAppend);
        setCheckedListTwo(updatedNumbers);
        setChooseCategories(removeChoice)
    } 
  };

  if((checkedList.length !== 0 && checkedList !== '[]') || (checkedListTwo.length !== 0 && checkedListTwo !== '[]')) {
    if (!isAllInfo) {
        setIsAllInfo(true);
      }
  }
  else {
    if(isAllInfo) {
        setIsAllInfo(false)
    }
  }


  useEffect(() => {
    setTimeout(() => {
        const creator = document.querySelector('.step-wrapper');
        if(creator) {
            
            creator.style.opacity = 1;
            creator.style.transform = 'translateY(0px)';
            
        }

    }, 100);
   }, []); 
    
  return (
    <form className='step-wrapper'>
        <div className='step-hero-wrapper'>
            <h1 className='step-main-title-progress'>Categories you Sell Products in</h1>
        </div>
        <div className='question-category-group'>
            <div className='category-box'>
                <div className='category-flex'>
                    <div className='category-half-container'>
                        
                        {categoryOne.map((categoryItem, categoryIndex) => {
                            return (
                                <div className='category-items'>
                                    <label className='category-label'>
                                        <div className='category-items-each'>
                                            <div className='category-checkbox-container'>
                                                <span className='checkbox-span'>
                                                    {checkedList.includes(categoryIndex) && 
                                                        <svg fill="#ffffff" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg" className="input-box-checked"><path d="M17 5.164V8l-8 8-4-4V9.164l4 4 8-8z"></path></svg>
                                                    }
                                                    <input data-checkboxindex={categoryIndex} data-isChecked={checkedList.includes(categoryIndex) ? 'true' : 'false'} className="checkbox-category-input" type="checkbox" onClick={handleCheckbox}/>
                                                </span>
                                            </div>
                                            <div className='category-text-container'>
                                                <p class="checkbox-title">{categoryItem}</p>
                                            </div>
                                        </div>
                                    </label>
                                    <div className='category-item-underline'><div className="underline-progress-category"></div></div>
                                </div>
                            );
                        })}
                    </div>

                    <div className='category-half-container'>
                        {categoryTwo.map((categoryItem, categoryIndexTwo) => {
                            return (
                                <div className='category-items'>
                                    <label className='category-label'>
                                        <div className='category-items-each'>
                                            <div className='category-checkbox-container'>
                                                <span className='checkbox-span'>
                                                    {checkedListTwo.includes(categoryIndexTwo) && 
                                                        <svg fill="#ffffff" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg" className="input-box-checked"><path d="M17 5.164V8l-8 8-4-4V9.164l4 4 8-8z"></path></svg>
                                                    }
                                                    <input data-checkboxindex={categoryIndexTwo} data-isChecked={checkedListTwo.includes(categoryIndexTwo) ? 'true' : 'false'} className="checkbox-category-input" type="checkbox" onClick={handleCheckboxTwo}/>
                                                </span>
                                            </div>
                                            <div className='category-text-container'>
                                                <p className="checkbox-title">{categoryItem}</p>
                                            </div>
                                        </div>
                                    </label>
                                    <div className='category-item-underline'><div className="underline-progress-category"></div></div>
                                </div>
                            );
                        })}
                    </div>
                    {/* ddd */}
                </div>
            </div>
        </div>
        <ButtonOpacity.Provider value={{isAllInfo, setIsAllInfo}}>
          <NextSeller onNextClicked={() => {onNextClicked();}} onPreviousClicked={onPreviousClicked}/>
        </ButtonOpacity.Provider>
    </form>
  );
}

export default CreatorCategory;
