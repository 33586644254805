import React, { useState, useRef, useEffect } from 'react'
import './Work.css'
import { Link, useNavigate } from 'react-router-dom';

import vueLogo from '../../Videos/Frame.png'
import backgroundImage from '../../Videos/background-image.jpg'
import effectVideo from '../../Videos/source1.mp4';
import effectVideoWebM from '../../Videos/source2.webm';
import Iphone from './Components/Iphone';
import vueKartLogo from '../../Videos/vuekartLogo.png'
import { PopupModal } from '../../Contexts/PopupModal';
import ShortPlayer from '../ShortPlayer';
import PopupPlayer from './Components/PopupPlayer';
import SVGBackground from '../../Videos/box-background.svg'
import svgCard3 from '../../Videos/card-3.svg';
import FooterWork from './Components/FooterWork';
import vids from '../../Videos/choc.mp4'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Work() {
    gsap.registerPlugin(ScrollTrigger);

    const ref = useRef(null);

    const [modalStatus, setModalStatus] = useState(false);

    if (modalStatus) {
        document.body.style.overflow = 'hidden';
    }
    else {
        document.body.style.overflow = 'auto';
    }

    useEffect(() => {
        const element = ref.current;
        gsap.fromTo(
          element.querySelector(".work__section__feature__1"),
          {
            opacity: 0,
            filter: 'blur(5px)',
            transform: 'translate3d(0px, 5rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'

          },
          {
            opacity: 1,
            filter: 'blur(0px)',
            transform: 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d',

            scrollTrigger: {
                trigger: element.querySelector(".work__section__feature__1"),
                // start: "bottom bottom",
                // end: "bottom center",
                // scrub: true
                toggleActions: 'restart none none pause'
              },
              duration: 1
          }
        );
      }, []);

      useEffect(() => {
        const element = ref.current;
        gsap.fromTo(
          element.querySelector(".footer__v2__start__container"),
          {
            opacity: 0,
            filter: 'blur(5px)',
            transform: 'translate3d(0px, 5rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'

          },
          {
            opacity: 1,
            filter: 'blur(0px)',
            transform: 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d',

            scrollTrigger: {
                trigger: element.querySelector(".footer__v2__start__container"),
                // start: "bottom bottom",
                // end: "bottom center",
                // scrub: true
                // toggleActions: 'restart pause reverse pause'
                toggleActions: 'restart none none pause'
              },
              duration: 1
          }
        );
      }, []);

      useEffect(() => {
        const element = ref.current;
        gsap.fromTo(
          element.querySelector(".footer__v2__logo__container"),
          {
            opacity: 0,
            filter: 'blur(5px)',
            transform: 'translate3d(0px, 5rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'

          },
          {
            opacity: 1,
            filter: 'blur(0px)',
            transform: 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d',

            scrollTrigger: {
                trigger: element.querySelector(".footer__v2__logo__container"),
                // start: "bottom bottom",
                // end: "bottom center",
                // scrub: true
                // toggleActions: 'restart pause reverse pause'
                toggleActions: 'restart none none pause'
              },
              duration: 1
          }
        );
      }, []);

      useEffect(() => {
        const element = ref.current;
        gsap.fromTo(
          element.querySelector(".work__nav"),
          {
            opacity: 0,
            filter: 'blur(5px)',
            transform: 'translate3d(0px, 3rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'

          },
          {
            opacity: 1,
            filter: 'blur(0px)',
            transform: 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d',

            duration: 1,
            ease: "ease",
          }
        );
      }, []);

      useEffect(() => {
        const element = ref.current;
        gsap.fromTo(
          element.querySelector(".work__main"),
          {
            opacity: 0,
            filter: 'blur(5px)',
            transform: 'translate3d(0px, 3rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'

          },
          {
            opacity: 1,
            filter: 'blur(0px)',
            transform: 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d',

            duration: 1,
            ease: "ease",
          }
        );
      }, []);


    //   useEffect(() => {
    //     const element = ref.current;
    //     gsap.fromTo(
    //       element.querySelector(".work__section"),
    //       {
    //         opacity: 1,
    //         filter: 'blur(0px)',
    //         transform: 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
           
    //         transformStyle: 'preserve-3d'

    //       },
    //       {
    //         opacity: 0,
    //         filter: 'blur(5px)',
    //         transform: 'translate3d(0px, -5rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
    //         transformStyle: 'preserve-3d',

    //         scrollTrigger: {
    //             trigger: element.querySelector(".work__section"),
    //             start: "bottom bottom",
    //             // end: "bottom center",
    //             // scrub: true
    //             // toggleActions: 'restart pause reverse pause'
    //             toggleActions: 'restart none none pause'
    //           },
    //           duration: 1
    //       }
    //     );
    //   }, []);
    
      
    
    //   useEffect(() => {
    //     const element = ref.current;
    //     gsap.from(element.querySelector(".line"), {
    //       scale: 0,
    //       ease: "none"
    //     });
    //   }, []);

    return (
      <div className='work__id' ref={ref}>
        <nav className='work__nav'>
            <div className='work__nav__container'>
                <Link to='/'>
                    <div className='work__nav__inner'>
                        <img className='work__nav__logo' src={vueKartLogo} />
                    </div>
                    
                </Link>
            </div>
        </nav>
        <main className='work__main'>
            <section className='work__section'>
                <div className='work__main__title'>
                    <h1 className='work__h1'>Join the new revolution of Video Commerce</h1>
                    <p className="work__subtext">Picture Perfect Sales:<em> </em>Elevate your online store with personalized product demo videos that captivate, convert, and reduce returns</p>
                    <button className='work__action__button'>Apply Now</button>
                </div>
                <div className='work__main__video'>
                    <div className='work__main__video__index'>
                        <div className='work__main__video__container'>
                        <PopupModal.Provider value={{modalStatus, setModalStatus}}>
                            <Iphone />
                        </PopupModal.Provider>
                            
                            {/* <img src='https://assets-global.website-files.com/64459fc8eb1482845c5a8b24/649479c58a35af7b05ae26a0_HYPERCARD_HOGSALT_02_230621%201.png' className='work__main__hero__image' /> */}
                        </div>
                        <div className='work__main__effect'>
                            <video loop muted autoPlay='autoplay' style={{backgroundImage: `url(${backgroundImage})`}}className='video__main__effect__video'>
                            <source src={effectVideo} data-wf-ignore="true" />
                            <source src={effectVideoWebM} data-wf-ignore="true" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>
            <section className='work__section__gap'></section>
            <section className='work__section__feature__1'>
                <div className='work__section__feature__1__top'>
                    <div className='work__section__top__inner'>
                        <div className='work__section__step'>
                            <span className='work__section__text__'>1</span>
                            <span className='work__section__text__'>—</span>
                            <span className='work__section__text__'>Meet Vuekart</span>
                        </div>
                        <h2 className='work__section__step__title'>Elevating the commerce to the New Heights</h2>
                        <p className="work__section__step__subtext">Bring your Product to life with Vuekart</p>
                    </div>
                </div>
                <div className='work__section__feature__1__bottom'>
                    <div className='__grid__1__container'>
                    <div className='__grid__1__img__container'>
                            <div className='__grid__1__img__inner'>
                            <img className='__grid__1__img' src={SVGBackground} loading="lazy" alt="2X points on every personal purchase and 3X points on expense management transactions" />
                            </div>
                    </div>
                    <div className='__grid__card__bottom'>
                            <div className="text-weight-medium">Maximizing Profits Never Felt So Rewarding</div>
                            <div className="text-weight-light">Sell and convert more with entertaining video experience.</div>
                    </div>
                    </div>

                    <div className='__grid__1__container __reverse__column'>
                    <div className='__grid__1__img__container'>
                            <div className='__grid__1__img__inner'>
                            <img className='__grid__1__img' src={'https://assets-global.website-files.com/64459fc8eb1482845c5a8b24/648344fae4318572bd874a4b_galileo-s-2-p-800.png'} loading="lazy" alt="2X points on every personal purchase and 3X points on expense management transactions" />
                            </div>
                    </div>
                    <div className='__grid__card__bottom'>
                            <div className="text-weight-medium">Video-Powered Shopping Experience</div>
                            <div className="text-weight-light">Transform Shopping Moments: Elevate with Snappy, Shoppable Videos.</div>
                    </div>
                    </div>

                    <div className='__grid__1__container'>
                    <div className='__grid__1__img__container'>
                            <div className='__grid__1__img__inner'>
                            <img className='__grid__1__img' src={svgCard3} loading="lazy" alt="2X points on every personal purchase and 3X points on expense management transactions" />
                            </div>
                    </div>
                    <div className='__grid__card__bottom'>
                            <div className="text-weight-medium">Get your product video within 2 days</div>
                            <div className="text-weight-light">Keep your customers engaged and informed with short form video.</div>
                    </div>
                    </div>
                </div>
            </section>
        </main>
        <FooterWork />
        <div className='work__modal__container'>
            {modalStatus ? 
            <PopupModal.Provider value={{modalStatus, setModalStatus}}>
                <PopupPlayer fireURL={vids} /> 
            </PopupModal.Provider>
            : ''}
        </div>
      </div>
    );
  }
  
  export default Work;