import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Home from './components/Home';
import Option from './components/Option';
import Creator from './components/creator/Creator.js';
import CreatorAbout from './components/creator/CreatorAbout.js';
import Seller from './components/seller/Seller';
import Pricing from './components/Pricing';
import Loading from './components/Loading';
import About from './components/About';
import Howto from './components/Howto';
import Thankyou from './components/Thankyou';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import ShortVideo from './components/ShortVideo';
import Test from './components/Test';
import ShortPlayer from './components/ShortPlayer';
import AdminUpload from './components/AdminUpload';
import VideoRoute from './components/VideoRoute';
import Disclaimer from './components/Disclaimer';
import Work from './components/work/Work.js';

function App() {
  return (
      
      <Router>
        {/* <Header /> */}
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/choose" element={<Option />}/>
          <Route exact path="/creator" element={<Creator />}/>
          <Route exact path="/creator/about" element={<CreatorAbout />}/>
          <Route exact path="/seller" element={<Seller />}/>
          <Route exact path="/pricing" element={<Pricing />}/>
          <Route exact path="/load" element={<Loading />}/>
          <Route exact path="/about" element={<About />}/>
          <Route exact path="/guide" element={<Howto />}/>
          <Route exact path="/ordered" element={<Thankyou />}/>
          <Route exact path="/privacy" element={<Privacy />}/>
          <Route exact path="/terms" element={<Terms />}/>
          <Route exact path="/disclaimer" element={<Disclaimer />} />
          {/* <Route exact path="/test" element={<Test />}/> */}
          <Route exact path="/video" element={<ShortVideo />}/>
          {/* <Route exact path="/dan" element={<ShortPlayer />}/> */}
          <Route exact path="/admin" element={<AdminUpload />}/>

          <Route exact path="/videos/:id" element={<VideoRoute />} />
          <Route exact path="/example" element={<Work />} />
        </Routes>
      </Router>
    
    
  );
}

export default App;
